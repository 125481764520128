import { values } from 'lodash-es';
import React from 'react';
import { styled } from 'styled-components';
import { Icon, IconProps } from '../Icon/Icon';
import {
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  IconProp,
  InformationCircleIcon,
} from '../Icon/Icons';
import { Theme } from '../Theme/Types';

export type Intent = (typeof Intent)[keyof typeof Intent];
export const Intent = {
  None: 'none',
  Primary: 'primary',
  Secondary: 'secondary',
  Success: 'success',
  Warning: 'warning',
  Danger: 'danger',
} as const;

export function intentColor(theme: Theme, intent: Intent | undefined): string {
  return theme.intent[intent ?? 'none'].bg;
}

export const IntentNoneIcon = styled(InformationCircleIcon)`
  color: ${({ theme }) => intentColor(theme, Intent.None)};
`;

export const IntentPrimaryIcon = styled(InformationCircleIcon)`
  color: ${({ theme }) => intentColor(theme, Intent.Primary)};
`;

export const IntentSuccessIcon = styled(CheckIcon)`
  color: ${({ theme }) => intentColor(theme, Intent.Success)};
`;

export const IntentWarningIcon = styled(ExclamationCircleIcon)`
  color: ${({ theme }) => intentColor(theme, Intent.Warning)};
`;

export const IntentDangerIcon = styled(ExclamationTriangleIcon)`
  color: ${({ theme }) => intentColor(theme, Intent.Danger)};
`;

export function intentIcon(intent: Intent | undefined): IconProp {
  switch (intent) {
    case Intent.Primary:
      return IntentPrimaryIcon;
    case Intent.Success:
      return IntentSuccessIcon;
    case Intent.Warning:
      return IntentWarningIcon;
    case Intent.Danger:
      return IntentDangerIcon;
    case undefined:
    case Intent.None:
    default:
      return IntentNoneIcon;
  }
}

export interface ItentIconProps {
  intent: Intent | undefined;
}

export function IntentIcon(props: ItentIconProps & Omit<IconProps, 'icon'>) {
  const { intent, ...iconProps } = props;
  return <Icon {...iconProps} icon={intentIcon(intent)} />;
}

export const intents: Intent[] = values(Intent);
