import { css, styled } from 'styled-components';
import { typography } from '../Theme/Mixins';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export const Table = styled.table.withConfig({ shouldForwardProp })<{ dense?: boolean }>`
  border-spacing: 0;

  ${(props) =>
    props.dense &&
    css`
      ${Th} {
        font-size: 14px;
        padding: 0.5rem;
      }
      ${Td} {
        font-size: 14px;
        padding: 0.5rem;
      }
    `}
`;

export const TRow = styled.tr`
  background-color: white;
  position: relative;

  // a pseudo element is used for the borders
  // because table borders with border-collapse
  // will break during a layout animation.
  // the pseudo element will show up as expected.
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: lightgray;
  }

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;

      &:hover {
        background-color: #b1b1b1;
      }
    `}
`;

export const THead = styled.thead`
  ${TRow} {
    background-color: #f8f8f8;
  }
`;

export const TBody = styled.tbody`
  background-color: white;
`;

export const TFoot = styled.tfoot``;

export const Th = styled.th`
  ${({ theme }) => typography(theme.table.th.font)}
  text-align: left;
  padding: ${({ theme }) => theme.table.th.padding};
  white-space: nowrap;
`;

export const Td = styled.td`
  ${({ theme }) => typography(theme.table.td.font)}
  padding: ${({ theme }) => theme.table.td.padding};
`;
