import React from 'react';
import { styled } from 'styled-components';
import { Icon } from '../Icon/Icon';
import { IconProp } from '../Icon/Icons';
import { shouldForwardProp } from '../Utils/shouldForwardProp';
import { buttonCSSReset } from './CSSReset';
import { Ripple } from './Ripple';

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconProp;
  onClick?: () => void;
}

export function IconButton(props: IconButtonProps) {
  return (
    <StyledIconButton {...props} onClick={props.onClick}>
      <Ripple />
      <Icon icon={props.icon} color="currentColor" />
    </StyledIconButton>
  );
}

const StyledIconButton = styled.button.withConfig({ shouldForwardProp })`
  ${buttonCSSReset}

  position: relative;
  justify-self: start;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.intent.primary.bg};
  }

  &:disabled {
    cursor: not-allowed;
    color: grey;
  }
`;
