import { noop } from 'lodash-es';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ToastProps } from './Toast';
import { ToastOverlay } from './ToastOverlay';

interface ToastContextState extends Toaster {
  toasts: Record<string, ToastProps>;
}

const ToastContext = React.createContext<ToastContextState>({} as any);

export interface ToastProviderProps {
  children?: React.ReactNode;
}

// TODO: refactor to remove this ToastProvider and instead use the OverlayProvider
// as a building block for the toasts uikit feature.
export function ToastProvider(props: ToastProviderProps) {
  const [toasts, setToasts] = useState<ToastContextState['toasts']>({});

  const dismiss = useCallback((id: string) => {
    setToasts((toasts) => {
      const next = { ...toasts };
      delete next[id];
      return next;
    });
  }, []);

  const present: Toaster['present'] = useCallback(
    (toast) => {
      setToasts((toasts) => {
        const id = toast.key ?? nextid();
        return {
          ...toasts,
          [id]: {
            ...toast,
            // we convert the timeout value to a date so that duplicate toasts result
            // in a new updated timeout date.
            timeout: timeoutToDate(toast.timeout ?? 15),
            onDismiss: () => {
              dismiss(id);
              toast.onDismiss?.();
            },
          },
        };
      });
    },
    [dismiss]
  );

  const value = useMemo(() => {
    return { toasts, present };
  }, [toasts, present]);

  return (
    <ToastContext.Provider value={value}>
      {props.children}
      <ToastOverlay toasts={toasts} />
    </ToastContext.Provider>
  );
}

export interface Toaster {
  present: (toast: ToastProps) => void;
}

export function useToaster(): Toaster {
  const context = useContext(ToastContext);
  return useMemo(() => {
    return {
      present: context?.present ?? noop,
    };
  }, [context]);
}

let id = 1;
function nextid(): string {
  id += 1;
  return String(id);
}

function timeoutToDate(timeout: number | Date | undefined): Date | undefined {
  if (!timeout) {
    return undefined;
  }

  if (typeof timeout === 'number') {
    return new Date(Date.now() + timeout * 1000);
  }

  return timeout;
}
