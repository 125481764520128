import React, { useMemo } from 'react';
import { ThemeProvider as StyledComponents } from 'styled-components';
import { deepAssign } from '../Utils/assignDeep';
import { DeepPartial } from '../Utils/deepPartial';
import { GlobalStyle } from './Global';
import { defaultTheme } from './Theme';
import { Theme } from './Types';

export interface ThemeProviderProps {
  theme?: DeepPartial<Theme>;
  children?: React.ReactNode;
}

export function ThemeProvider(props: ThemeProviderProps) {
  const theme = useMemo(() => {
    return createTheme(props.theme ?? {});
  }, [props.theme]);

  return (
    <StyledComponents theme={theme}>
      <GlobalStyle />
      {props.children}
    </StyledComponents>
  );
}

export function createTheme(overrides: DeepPartial<Theme>): Theme {
  return deepAssign({}, defaultTheme, overrides);
}
