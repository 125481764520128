import React, { useEffect, useId, useMemo, useSyncExternalStore } from 'react';
import { useEvent } from './useEvent';

export interface QueryState<TOut> {
  data: TOut | undefined;
  error: unknown | undefined;
  isFetching: boolean;
  refetch: () => Promise<void>;
}

/**
 * UseAsync is a hook that can be used to run asynchronous functions as component side effects
 * in a similar way to the useEffect hook from react itself.
 * The callback function will be run once when the component mounts and any time the `deps`
 * array changes.
 * @param callback A function that returns a promise for some value.
 * @param deps A react hook dependencies array.
 * @returns An object representing the state of the async effect (isFetching/error/data)
 */
export function useAsync<T>(callback: () => Promise<T>, deps: React.DependencyList = []): QueryState<T> {
  const sequence = useMemo(() => createSequence(), []);

  // ComponentId is a unique identifier for this particular hook
  // in the context of the entire react application.
  const id = useId();

  // Id is a unique identifier for the current hook with
  // respect to the list of dependencies.
  // If the dependencies change, a new sequence number will
  // be used for the id.
  // This identifier is used in the global `cache` map to
  // store state that belongs to this component.
  const effectId = useMemo(() => id + sequence(), [id, sequence, ...deps]);

  const runEffect = useEvent(async (store: Store<InternalQueryState>, effectId: string, force = false) => {
    // If the store's current state holds the given effectId that means the effect is
    // already executing or has executed and we don't want to run it again.
    // In the case that there's a new effectId we need to run the effect (i.e. the `deps` array has changed).
    // In the case that `force` is true we'll always run the effect again (i.e. the `refetch()` use-case)
    if (!force && store.getState().effectId === effectId) {
      return;
    }

    // Update the store's state to indicate the effect is running
    // including the the effectId so that we can track the active effect.
    store.setState((state) => ({ ...state, effectId, isFetching: true }));

    try {
      // Execute the caller's effect callback (i.e. do the async thing)
      const data = await callback();

      // Before we update the store's state with the result we need
      // to double check that this function invocation is still the
      // active effect.
      // If the store's state indicates a different effect is running
      // then we have a stale result which will need to be ignored.
      // Stale results can happen when effects are run faster than they
      // can complete (maybe a user clicks a "refresh" button multiple times.)
      if (store.getState().effectId === effectId) {
        store.setState((state) => ({ ...state, data }));
      }
    } catch (error) {
      if (store.getState().effectId === effectId) {
        store.setState((state) => ({ ...state, error }));
      }
    } finally {
      if (store.getState().effectId === effectId) {
        store.setState((state) => ({ ...state, isFetching: false }));
      }
    }
  });

  const store = useMemo(() => {
    const store = createStore<InternalQueryState>({
      effectId: undefined,
      isFetching: true,
      data: undefined,
      error: undefined,
    });

    return store;
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await runEffect(store, effectId);
      } catch (error) {
        console.error(error); // log sync & async errors
      }
    })();
    return () => {};
  }, [store, effectId, runEffect]);

  const currentState = useSyncExternalStore(store.subscribe, store.getState);

  return {
    isFetching: currentState.isFetching,
    data: currentState.data,
    error: currentState.error,
    refetch: useEvent(() => {
      return runEffect(store, effectId, true);
    }),
  };
}

interface Store<T> {
  getState: () => T;
  setState: (mutate: (state: T) => T) => void;
  subscribe: (listener: () => void) => () => void;
}

function createStore<T>(initialState: T): Store<T> {
  let state = initialState;

  const getState = () => state;

  const listeners = new Set<() => void>();

  const setState = (fn: (state: T) => T) => {
    state = fn(state);
    for (const listener of listeners) {
      listener();
    }
  };

  const subscribe = (listener: () => void) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  return { getState, setState, subscribe };
}

type InternalQueryState = {
  effectId: string | undefined;
  data: any;
  error: unknown | undefined;
  isFetching: boolean;
};

function createSequence() {
  let current = 1;
  return (): number => {
    return current++;
  };
}
