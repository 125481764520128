import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { css, styled } from 'styled-components';
import { springQuick } from '../Animation/DefaultAnimations';
import { colors } from '../Color/Colors';
import { Flex } from '../Flex/Flex';
import { useEvent } from '../Hooks/useEvent';
import { Icon } from '../Icon/Icon';
import { IconProp } from '../Icon/Icons';
import { Intent } from '../Intent/Intent';
import { breakpointsMixin } from '../Responsive/Breakpoints';
import { border } from '../Theme/Mixins';
import { Theme } from '../Theme/Types';
import { Text } from '../Typography/Typography';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface AlertBarProps {
  title: string;
  icon?: IconProp;
  intent?: Intent;
  actions?: React.ReactNode | React.ReactNode[];
  dismissable?: boolean;
  onDismiss?: () => void;
}

export function AlertBar(props: AlertBarProps) {
  const [dismissed, setDismissed] = useState(false);

  const onDismiss = useEvent(() => {
    if (props.dismissable) {
      setDismissed(true);
    }
    props.onDismiss?.();
  });

  return (
    <AnimatePresence>
      {!dismissed && (
        <motion.div
          initial={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ springQuick }}
          style={{ overflow: 'hidden' }}
        >
          <StyledBar intent={props.intent} horizontal gap={1} align="center">
            {props.icon && <Icon icon={props.icon} size={1.2} />}

            <Text>{props.title}</Text>

            {(props.actions || props.dismissable || props.onDismiss) && (
              <StyledActions horizontal gap={1}>
                {props.actions}

                {(props.dismissable || props.onDismiss) && (
                  <Text underlined onClick={onDismiss}>
                    Dismiss
                  </Text>
                )}
              </StyledActions>
            )}
          </StyledBar>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const StyledBar = styled(Flex).withConfig({ shouldForwardProp })<{ intent?: Intent }>`
  ${(props) => border(props.theme.alertBar.border)}

  ${(props) => intentStyle(props.theme.alertBar.intents, props.intent)}

  padding: ${({ theme }) => theme.alertBar.padding};

  height: 46px;
`;

function intentStyle(styles: Theme['alertBar']['intents'], intent: Intent = Intent.None) {
  const style = styles[intent];
  return css`
    color: ${style.fg};
    background-color: ${style.bg};
    border: ${border(style.border)};
  `;
}

const StyledActions = styled(Flex)`
  // makes the element aligned to the right of the parent flexbox
  margin-left: auto;

  padding-left: 16px;
  border-left: 1px solid ${colors.slate_500};

  ${(props) =>
    breakpointsMixin(
      props.theme.typography.typeScale(-1),
      (value) => css`
        font-size: ${value};
      `
    )};
`;
