import { useCallback, useMemo, useState } from 'react';
import { useEvent } from '../../Hooks/useEvent';
import { RowID } from '../DataTableContracts';

interface RowExpansionHook {
  toggle: (id: RowID) => void;
  isExpanded: (id: RowID) => boolean;
}

export function useRowExpansion(): RowExpansionHook {
  const [expanded, setExpanded] = useState<Record<RowID, boolean>>({});

  const toggle = useEvent((id: RowID) => {
    setExpanded((current) => {
      const next = { ...current };
      if (current[id] === true) {
        delete next[id];
      } else {
        next[id] = true;
      }
      return next;
    });
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: linter doesn't understand Record usage
  const isExpanded = useCallback(
    (id: RowID) => {
      return !!expanded[id];
    },
    [expanded]
  );

  return useMemo(() => ({ toggle, isExpanded }), [toggle, isExpanded]);
}
