import React from 'react';

export function MontserratFontFace() {
  return (
    <style>
      {`
      @font-face {
        font-family: Montserrat;
        font-weight: 100 1000;
        src: url('${require('./Assets/Montserrat.woff2')}') format('woff2');
      }
    `}
    </style>
  );
}
