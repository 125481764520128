import { debounce } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useEvent } from './useEvent';

export type AnyFunction = (...args: any) => any;

export interface DebouncedEventHook<T extends AnyFunction> {
  callback: T;
  isWaiting: boolean;
}

// https://egghead.io/lessons/react-create-a-debounced-event-handler-with-the-usememo-hook
export function useDebouncedEvent<T extends AnyFunction>(handler: T, delayMilliseconds: number): DebouncedEventHook<T> {
  const [isWaiting, setIsWaiting] = useState(false);

  const callbackInner = useEvent((...params: any[]) => {
    setIsWaiting(false);
    return handler(...params);
  });

  const debouncedCallback = useMemo(
    () => debounce(callbackInner, delayMilliseconds),
    [callbackInner, delayMilliseconds]
  );

  const callbackOuter = useEvent((...params: any[]) => {
    setIsWaiting(true);
    debouncedCallback(...params);
  });

  return {
    isWaiting: isWaiting,
    callback: callbackOuter as unknown as T,
  };
}
