import { LayoutGroup, MotionConfig, motion } from 'framer-motion';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import { Spacer } from '../Spacer/Spacer';
import { Text } from '../Typography/Typography';
import { TabProps } from './Tab';

export interface TabsProps {
  children?: React.ReactNode;
}

export function Tabs(props: TabsProps) {
  const [selected, setSelected] = useState(0);

  const tabs = React.Children.map(props.children, (child, index) => {
    const tab = child as React.ReactElement<TabProps>;
    return {
      label: tab.props.label,
      index: index,
      content: tab,
    };
  });

  return (
    <MotionConfig transition={{ duration: 0.2 }}>
      <Spacer justify="stretch">
        <LayoutGroup>
          <StyledHeader horizontal gap={0}>
            {tabs?.map((tab) => (
              <StyledTabHeader
                key={tab.index}
                onClick={() => setSelected(tab.index)}
                animate={{ background: selected === tab.index ? 'rgba(238, 238, 238, 1)' : 'rgba(238, 238, 238, 0)' }}
              >
                <Text>{tab.label}</Text>
                {selected === tab.index ? <SelectionIndicator layoutId="underline" /> : null}
              </StyledTabHeader>
            ))}
          </StyledHeader>
        </LayoutGroup>
        <StyledTabContent>{tabs?.[selected].content}</StyledTabContent>
      </Spacer>
    </MotionConfig>
  );
}

const StyledHeader = styled(Spacer)`
  border-bottom: 1px solid #dbdbdb;
`;

const StyledTabHeader = motion(styled.div`
  position: relative;
  padding: 1rem 0;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${Text} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`);

const StyledTabContent = styled.div`
  padding: 1rem;
`;

const SelectionIndicator = motion(styled.div`
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: ${(props) => props.theme.intent.primary.bg};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-left: 1rem;
  margin-right: 1rem;
  width: calc(100% - 2rem);
`);
