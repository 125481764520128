import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext, useMemo } from 'react';
import { FieldPath, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { styled } from 'styled-components';
import * as z from 'zod';

export interface FormProps<T extends z.ZodTypeAny> {
  schema: T;
  onSubmit?: (value: z.infer<T>) => Promise<void> | void;
  children?: React.ReactNode;
  debug?: boolean;
  /**
   * ValidateOnBlur can be used to configure if the form should
   * validate on blur i.e. when the user tabs out of an input.
   * Defaults to `true`.
   */
  validateOnBlur?: boolean;
}

/**
 * FieldPath is a utility that takes a schema and a field path string like "example" or "example.property"
 * and returns the field path unchanged.
 *
 * Essentially the function does nothing at runtime.
 *
 * The purpose is to get a compilation check that the field path exists in the schema so that form inputs
 * can be named correctly.
 *
 * e.g.
 *
 * <TextInput name={fieldPath(schema, 'example.property')} />
 */
export function fieldPath<T extends z.ZodTypeAny, TFieldName extends FieldPath<z.infer<T>> = FieldPath<z.infer<T>>>(
  _schema: T,
  name: TFieldName
): string {
  return name;
}

export function Form<T extends z.ZodTypeAny>(props: FormProps<T>) {
  const form = useForm({
    mode: props.validateOnBlur ? 'onBlur' : 'onSubmit',
    resolver: zodResolver(props.schema),
  });

  const onSubmit = useCallback(
    (data: FieldValues, _event?: React.BaseSyntheticEvent) => {
      if (props.onSubmit) {
        const result = props.onSubmit(data as T);
        if (result instanceof Promise) {
          // making sure the promise we return to react-hook-form
          // doesn't throw unhandled errors because it causes the
          // library to crash
          return result.catch((error) => console.error(error));
        }
      }
    },
    [props.onSubmit]
  );

  // Calculate if we want to display the "*" on required form fields.
  // The decision is made by checking to see if the form has any optional fields.
  // If a form has optional fields then we want to clearly mark which fields are
  // actually required.
  // If there are no optional fields (i.e. all fields are required) then we want
  // to hide the "*" because someone told me to do that and because the login
  // form used to show "*" and someone else complained that it shouldn't because
  // all form fields are required and it's redundant...
  const showRequiredStar = useMemo(() => hasOptionalFields(props.schema), [props.schema]);

  const extraFormContext = useMemo(
    () => ({ schema: props.schema, showRequiredStar }),
    [props.schema, showRequiredStar]
  );

  return (
    <FormProvider {...form}>
      <ExtraFormContext.Provider value={extraFormContext}>
        <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
          {props.children}
          {props.debug && (
            <>
              <pre>{JSON.stringify(form.watch(), null, 2)}</pre>
              <pre>{JSON.stringify(form.formState, null, 2)}</pre>
              <pre>{JSON.stringify(form.formState.errors, null, 2)}</pre>
            </>
          )}
        </StyledForm>
      </ExtraFormContext.Provider>
    </FormProvider>
  );
}

export const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
  text-align: left;
  font-size: 1rem;
`;

interface ExtraContext {
  schema: z.ZodTypeAny;
  showRequiredStar: boolean;
}

const ExtraFormContext = React.createContext<ExtraContext>({} as any);

export function useExtraFormContext(): ExtraContext | undefined {
  const context = useContext(ExtraFormContext);
  if (!context) {
    return undefined;
  }
  return context;
}

/**
 * HasRequiredFields returns true when the schema is an object schema
 * with any top-level fields that are required (not optional).
 *
 * The implementation isn't perfect because it's really hard to know
 * if there are ever going to be any required fields given zod schemas
 * allow for dynamic validation rules...
 *
 * But the idea is that we only want to display the "*" on form fields
 * if the form contains required fields and this is how we're doing it.
 */
function hasOptionalFields(schema: z.ZodTypeAny): boolean {
  if (schema instanceof z.ZodObject) {
    return Object.keys(schema.shape).some((key) => {
      const field = schema.shape[key] as z.ZodTypeAny;
      // Field is optional when "isOptional()" is true and it's not a boolean field.
      // Ignoring boolean fields because it's so common to always make it optional
      // and we don't want it to impact our decision to show the required "*" on forms.
      return field.isOptional() && !(field?._def?.innerType instanceof z.ZodBoolean);
    });
  }

  return false;
}
