import React, { useRef } from 'react';
import { css, styled } from 'styled-components';
import { colors } from '../../Color/Colors';
import { useAutoFocus } from '../../Hooks/useAutoFocus';
import { useEvent } from '../../Hooks/useEvent';
import { Breakpoints, breakpointsMixin } from '../../Responsive/Breakpoints';
import { isNullOrWhitespace } from '../../Text';
import { border, typography } from '../../Theme/Mixins';
import { TypeScale, TypeScaleSize, createTypeScale } from '../../Typography/TypeScale';
import { shouldForwardProp } from '../../Utils/shouldForwardProp';
import { FormInputProps, formInput } from '../FormInput';

export interface TextInputProps extends FormInputProps<string> {
  placeholder?: string;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  type?: 'text' | 'password';
  autoComplete?: boolean;
  width?: number;
  scale?: Breakpoints<TypeScaleSize>;
}

export const TextInput = formInput((props: TextInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  useAutoFocus(ref, props.autoFocus);

  const onChange = useEvent((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.currentTarget.value, event);
    }
  });

  return (
    <StyledTextInput
      {...props}
      ref={ref}
      value={props.value ?? ''}
      onChange={onChange}
      placeholder={isNullOrWhitespace(props.placeholder) ? undefined : `${props.placeholder}...`}
      autoComplete={props.autoComplete ? undefined : 'off'}
      data-testid={props.name}
      isInvalid={props.isInvalid}
    />
  );
});

const paddingScale = createTypeScale(TypeScale.PerfectFourth);

export const StyledTextInput = styled.input.withConfig({ shouldForwardProp })<{
  isInvalid?: boolean;
  scale?: TextInputProps['scale'];
}>`
  outline: none;

  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};

  ${(props) => typography(props.theme.input.font)};
  ${(props) => border(props.theme.input.border)};

  ::placeholder {
    color: ${colors.slate_500};
    opacity: 1;

    ${(props) => typography(props.theme.input.placeholder.font)}
  }

  ${(props) =>
    !(props.disabled || props.readOnly) &&
    css`
      &:focus {
        outline: 2px solid ${props.theme.intent.primary.bg};
        outline-offset: -2px;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${colors.slate_500};
      background-color: ${colors.slate_100};
      border-color: ${colors.slate_300};
    `}

  ${(props) =>
    props.readOnly &&
    css`
      cursor: default;
      border-color: ${colors.slate_300};
    `}

  ${(props) =>
    props.isInvalid &&
    css`
      border-color: ${props.theme.intent.danger.bg};
    `}

  /* responsive padding that uses the "scale" prop (typescale) */
  ${({ scale }) =>
    breakpointsMixin(
      scale ?? 0,
      (scale) => css`
        padding: ${paddingScale(scale, '10px')};
      `
    )}
`;
