import React from 'react';

export interface DataSource<T> {
  data: T[];
  rowID: (row: T) => RowID;
  columns: Column<T>[];
}

export interface Column<T> {
  /**
   * Key must uniquely identify this column.
   * Defaults to "header" if empty.
   */
  key?: string;
  /**
   * Header is the text to display for this column in the header.
   */
  header: string;
  /**
   * Selector must return a value to be used for sorting and filtering.
   * The selector typically just returns a field from the row.
   *
   * The selector is optional but at least once of "selector" or "render"
   * should be passed to display content in the table cell.
   * If selector is not used then the column cannot support sort/filter/export etc.
   */
  selector?: (row: T) => CellValue;
  /**
   * Render let's you customize the table cell for this row/column.
   * If undefined the result from the selector will be used instead.
   */
  render?: React.ComponentType<CellRenderProps<T>>;
  /**
   * Sortable allows you to disable column sorting for this column.
   * Columns are sortable by default.
   */
  sortable?: boolean;
  /**
   * Filterable allows you to disable column filtering for this column.
   * Columns are fitlerable by default.
   */
  filterable?: boolean;
}

export type RowID = string | number;

export type CellValue = string | number | boolean | null | undefined;

export interface CellRenderProps<T> {
  row: T;
  value: CellValue;
}

export interface ExpandedRowRenderProps<T> {
  row: T;
}

export function columnKey(column: Column<any>): string {
  return column.key ?? column.header;
}
