import { motion } from 'framer-motion';
import React from 'react';
import { styled } from 'styled-components';
import { springDefault } from '../Animation/DefaultAnimations';
import { useEqualityMemo } from '../Hooks/useEqualityMemo';
import { useEvent } from '../Hooks/useEvent';
import { useLocalStorage } from '../Hooks/useLocalStorage';
import { shouldForwardProp } from '../Utils/shouldForwardProp';
import { DrawerCollapser } from './DrawerCollapser';
import { DrawerContext } from './DrawerContext';

export interface DrawerProps {
  children?: React.ReactNode;
}

export function Drawer(props: DrawerProps) {
  const [collapse, setCollapse] = useLocalStorage('drawer.collapse', false);

  const toggle = useEvent(() => setCollapse((v) => !v));

  const contextValue = useEqualityMemo({
    collapse,
    toggle,
  });

  return (
    <DrawerContext.Provider value={contextValue}>
      <StyledDrawer
        {...contextValue}
        initial={false}
        animate={{ width: contextValue.collapse ? 64 : 280 }}
        transition={springDefault}
      >
        {props.children}

        <DrawerCollapser />
      </StyledDrawer>
    </DrawerContext.Provider>
  );
}

const StyledDrawer = motion(styled.div.withConfig({ shouldForwardProp })<DrawerContext>`
  height: 100vh;
  color: ${(props) => props.theme.drawer.fg};
  background-color: ${(props) => props.theme.drawer.bg};
  box-shadow: rgba(17, 38, 146, 0.05) 0px 0px 30px 0px;
  overflow: hidden;

  // defensive css because legacy angularjs code has font-size 13px globally
  font-size: 16px;

  display: grid;

  grid-template-areas:
    'header'
    'content'
    'footer'
    'collapser';

  grid-template-columns: auto;

  grid-template-rows:
    min-content
    auto
    min-content
    min-content;
`);
