import { css, styled } from 'styled-components';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { typography } from '../Theme/Mixins';
import { TypeScale, TypeScaleSize, createTypeScale } from '../Typography/TypeScale';
import { shouldForwardProp } from '../Utils/shouldForwardProp';
import { buttonCSSReset } from './CSSReset';

export interface StyledTextButtonProps {
  scale?: Breakpoints<TypeScaleSize>;
}

const fontScale = createTypeScale(TypeScale.MajorSecond);

export const StyledTextButton = styled.button.withConfig({ shouldForwardProp })<StyledTextButtonProps>`
  ${buttonCSSReset}

  ${(props) => typography(props.theme.button.font)}

    ${({ scale }) =>
      breakpointsMixin(
        scale ?? 0,
        (scale) => css`
        font-size: ${fontScale(scale, '14px')};
      `
      )}

  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  overflow: hidden;
`;
