import React from 'react';
import { OverlayProvider } from './Overlay/OverlayProvider';
import { ThemeProvider } from './Theme/ThemeProvider';
import { Theme } from './Theme/Types';
import { ToastProvider } from './Toast/ToastProvider';

export interface UIProviderProps {
  theme?: Theme;
  children?: React.ReactNode;
}

export function UIProvider(props: UIProviderProps) {
  return (
    <ThemeProvider theme={props.theme}>
      <OverlayProvider>
        <ToastProvider>{props.children}</ToastProvider>
      </OverlayProvider>
    </ThemeProvider>
  );
}
