import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { styled } from 'styled-components';
import { Paper } from '../Paper/Paper';
import { Popover, PopoverProps } from '../Popover/Popover';

export interface TooltipProps {
  tip?: React.ReactNode;
  children?: React.ReactNode;
  open?: PopoverProps['open'];
  hover?: PopoverProps['hover'];
  static?: PopoverProps['static'];
  placement?: PopoverProps['placement'];
  onDismiss?: PopoverProps['onDismiss'];
}

export function Tooltip(props: TooltipProps) {
  return (
    <Popover
      open={props.open}
      hover={props.hover}
      placement={props.placement}
      styleCursor={false}
      static={props.static}
      popover={({ open }) => (
        <AnimatePresence>
          {open && (
            <StyledTooltip
              elevation={2}
              initial={{ opacity: 0, transform: 'scale(0.9)' }}
              animate={{ opacity: 1, transform: 'scale(1)' }}
              exit={{ opacity: 0, transform: 'scale(0.9)' }}
              transition={{ duration: 0.1 }}
            >
              {props.tip}
            </StyledTooltip>
          )}
        </AnimatePresence>
      )}
      onDismiss={props.onDismiss}
    >
      {props.children}
    </Popover>
  );
}

const StyledTooltip = motion(styled(Paper)`
  color: white;
  background-color: #757575;
  font-size: 14px;
  padding: 0.5rem;
`);
