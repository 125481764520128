import React, { useState } from 'react';
import { useAsync } from '../../Hooks/useAsync';
import { useAsyncEvent } from '../../Hooks/useAsyncEvent';
import { useEqualityMemo } from '../../Hooks/useEqualityMemo';
import { useEvent } from '../../Hooks/useEvent';
import { PaginationHook, defaultPageSize } from './PaginationHook';

export interface SimplePaginatedResponse<TItem> {
  items: TItem[];
}

/**
 * SimplePaginationFetcher receives a zero-indexed page number
 * and page size and should return the corresponding page of data.
 * The page number always starts from zero!
 */
export type SimplePaginationFetcher<TItem> = (
  pageNumber: number,
  pageSize: number
) => Promise<SimplePaginatedResponse<TItem>>;

export function useSimplePagination<TItem>(
  fetcher: SimplePaginationFetcher<TItem>,
  deps: React.DependencyList
): PaginationHook<TItem> {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [items, setItems] = useState<TItem[]>([]);

  const loadPage = useEvent(async (pageNumber: number) => {
    const response = await fetcher(pageNumber, pageSize);
    setCurrentPage(pageNumber);
    setItems(response.items);
  });

  const reset = useAsyncEvent(async () => {
    setCurrentPage(0);
    setItems([]);
    await loadPage(0);
  });

  const initial = useAsync(async () => {
    await reset.callback();
  }, deps);

  const next = useAsyncEvent(async () => {
    await loadPage(currentPage + 1);
  });

  const previous = useAsyncEvent(async () => {
    if (currentPage > 0) {
      await loadPage(currentPage - 1);
    }
  });

  const refresh = useAsyncEvent(async () => {
    await loadPage(currentPage);
  });

  return useEqualityMemo({
    items,
    currentPage,
    pageSize,
    setPageSize,
    next: next.callback,
    previous: previous.callback,
    refresh: refresh.callback,
    reset: reset.callback,
    isLoading: initial.isFetching || next.isFetching || previous.isFetching || refresh.isFetching,
    error: initial.error ?? refresh.error ?? previous.error ?? refresh.error,
  });
}
