import { DeepMap, FieldError } from 'react-hook-form';

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

// This function is too complicated; the original idea was to make a core function that
// could be safely used to convert error objects into text for display.
// The idea was to convert unknown stuff into general error messages like "A browser error occurred"
// or "An unknown error occurred" but keep any usefull error messages from our API or form validation library.
//
// This needs work and test cases.
//
// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: yeah my bad. this is a problem.
export function errorText(
  text: string | Error | FieldError | DeepMap<unknown, FieldError> | unknown,
  fallbackText = UnknownErrorMessage
): string {
  if (text === null || text === undefined) {
    return fallbackText;
  }

  if (typeof text === 'string') {
    return text || fallbackText;
  }

  if (text instanceof SyntaxError) {
    return 'A browser error occurred';
  }

  // grpc errors from our frontend grpc stack
  if (text instanceof Error && 'code' in text && 'metadata' in text) {
    return grpcErrorToText(text as GRPCError);
  }

  // react-hook-form FieldError
  if (typeof text === 'object' && 'type' in text) {
    if (text.type === 'required') {
      return 'Required';
    }
    if ('message' in text && typeof text.message === 'string') {
      return text.message || fallbackText;
    }
    return 'Validation error';
  }

  // general object with a ".message" string (like a new Error(""))
  if (typeof text === 'object' && 'message' in text && typeof text.message === 'string') {
    return text.message || fallbackText;
  }

  return fallbackText;
}

const UnknownErrorMessage = 'An unknown error occurred';

export function isNullOrWhitespace(text?: string | null | undefined) {
  return text === undefined || text === null || text.trim() === '';
}

// this is duck type so that we can deal with our GrpcWebError type
// without taking a dependency on grpckit.
type GRPCError = Error & { code: GRPCStatusCode; metadata: Headers };

/**
 * GrpcErrorToText converts a GRPC error to a user readable string.
 *
 * https://grpc.github.io/grpc/core/md_doc_statuscodes.html
 * @param error
 */
function grpcErrorToText(error: GRPCError): string {
  if (!isNullOrWhitespace(error.message)) {
    return error.message;
  }
  return grpcCodeToText(error.code);
}

function grpcCodeToText(code: GRPCStatusCode): string {
  switch (code) {
    case GRPCStatusCode.Canceled:
      return 'Operation canceled';
    case GRPCStatusCode.Unknown:
      return 'Internal server error';
    case GRPCStatusCode.InvalidArgument:
      return 'Bad request';
    case GRPCStatusCode.DeadlineExceeded:
      return 'Operation timeout';
    case GRPCStatusCode.NotFound:
      return 'Resource not found';
    case GRPCStatusCode.AlreadyExists:
      return 'Resource already exists';
    case GRPCStatusCode.PermissionDenied:
      return 'Permission denied';
    case GRPCStatusCode.ResourceExhausted:
      return 'Resource exhausted';
    case GRPCStatusCode.FailedPrecondition:
      return 'Failed precondition';
    case GRPCStatusCode.Aborted:
      return 'Operation aborted';
    case GRPCStatusCode.OutOfRange:
      return 'Out of range';
    case GRPCStatusCode.Unimplemented:
      return 'Unimplemented';
    case GRPCStatusCode.Internal:
      return 'Internal server error';
    case GRPCStatusCode.Unavailable:
      return 'Server unavailable';
    case GRPCStatusCode.DataLoss:
      return 'Internal server error (15)';
    case GRPCStatusCode.Unauthenticated:
      return 'Unauthenticated';
    default:
      return 'An unknown server error occurred';
  }
}

enum GRPCStatusCode {
  OK = 0,
  Canceled = 1,
  Unknown = 2,
  InvalidArgument = 3,
  DeadlineExceeded = 4,
  NotFound = 5,
  AlreadyExists = 6,
  PermissionDenied = 7,
  ResourceExhausted = 8,
  FailedPrecondition = 9,
  Aborted = 10,
  OutOfRange = 11,
  Unimplemented = 12,
  Internal = 13,
  Unavailable = 14,
  DataLoss = 15,
  Unauthenticated = 16,
}
