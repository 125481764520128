import { Intl, Temporal, toTemporalInstant } from '@js-temporal/polyfill';

/**
 * The Temporal API is used in our date-time related inputs
 * but until the API is part of all browser engines we'll
 * export the polyfil so that applications that depend on the
 * uikit can easily access the required runtime objects/functions.
 */
export { Temporal, Intl, toTemporalInstant };

export const Now = Temporal.Now;
export const Instant = Temporal.Instant;
export const ZonedDateTime = Temporal.ZonedDateTime;
export const PlainTime = Temporal.PlainTime;
export const PlainDate = Temporal.PlainDate;
export const PlainDateTime = Temporal.PlainDateTime;
export const PlainMonthDay = Temporal.PlainMonthDay;
export const PlainYearMonth = Temporal.PlainYearMonth;
export const Duration = Temporal.Duration;
export const TimeZone = Temporal.TimeZone;

/**
 * ToDate is a utility function to convert from a ZonedDateTime or Instant
 * to a Javascript Date object.
 *
 * The utility accepts ZonedDateTime and Instant objects because these types
 * represent an exact point in time which matches the abstraction that the
 * Javascript Date object provides.
 *
 * For context a Date object represents time internally using the number of
 * milliseconds since epoch and does not hold information about timezones
 * nor the lack of a specific time or date (i.e. cannot represent a PlainDate or PlainTime)
 *
 * The Date object does timezone offset information; but it's worth noting
 * that this information is limited because it's only an offset and cannot
 * help us with things like daylight savings time or other timezone related
 * things; an offset is the same as +- some duration and doesn't match expectations
 * around timezone internationalization.
 *
 * @param datetime A Temporal.ZonedDateTime or Temporal.Instant object.
 * @returns A Javascript Date object.
 */
export function toDate(datetime: Temporal.ZonedDateTime | Temporal.Instant): Date {
  return new Date(datetime.epochMilliseconds);
}

/**
 * ToInstant converts a Javascript Date object into a Temporal.Instant object.
 *
 * @param date A Javascript Date object.
 * @returns
 */
export function toInstant(date: Date): Temporal.Instant {
  return Temporal.Instant.fromEpochMilliseconds(date.getTime());
}

// cached browser default options for locale and timezone
const browserDefaults = new Intl.DateTimeFormat().resolvedOptions();

/**
 * BrowserTimeZone returns the timezone identifier provided by the browser.
 * Note that it's possible for the timezone to incorrectly
 * reflect the actual timezone a user resides in given they
 * may have misconfigured their browser settings, be travelling, etc.
 */
export function browserTimeZone(): string {
  return browserDefaults.timeZone;
}

/**
 * BrowserLocale returns the locale identifier provided by the browser.
 * Note that it's possible for the locale to incorrectly
 * reflect the actual locale that a user prefers in given they
 * may have misconfigured their browser settings.
 */
export function browserLocale(): string {
  return browserDefaults.locale;
}

/**
 * IsBefore returns true when `a` is before `b`.
 */
export function isBefore(a: Temporal.Instant, b: Temporal.Instant): boolean {
  return a.until(b).sign === 1;
}
