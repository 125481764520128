import React from 'react';
import { css, styled } from 'styled-components';
import { IconButton } from '../Buttons/IconButton';
import { Flex } from '../Flex/Flex';
import { Icon } from '../Icon/Icon';
import { IconProp, XMarkIcon } from '../Icon/Icons';
import { Intent } from '../Intent/Intent';
import { border, typography } from '../Theme/Mixins';
import { Theme } from '../Theme/Types';
import { Text } from '../Typography/Typography';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface CalloutProps {
  intent?: Intent;
  title?: string;
  subtitle?: string;
  iconLeft?: IconProp;
  actions?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode;
  onDismiss?: () => void;
}

export function Callout(props: CalloutProps) {
  return (
    <StyledDiv intent={props.intent} gap={1} horizontal align="center" wrap>
      {props.iconLeft && (
        <StyledCircle intent={props.intent}>
          <Icon icon={props.iconLeft} />
        </StyledCircle>
      )}

      {props.title && (
        <Flex gap={0} flex={1} shrink={1} style={{ minWidth: 100 }}>
          <Text bold>{props.title}</Text>
          {props.subtitle && (
            <Text scale={-1} muted>
              {props.subtitle}
            </Text>
          )}
        </Flex>
      )}

      {/* perhaps we shouldn't support direct children */}
      {props.children && <div style={{ flex: 1 }}>{props.children}</div>}

      {props.actions && (
        <Flex horizontal gap={1} shrink={0}>
          {Array.isArray(props.actions)
            ? props.actions.map((node, i) => <React.Fragment key={i}>{node}</React.Fragment>)
            : props.actions}
        </Flex>
      )}

      {props.onDismiss && <IconButton icon={XMarkIcon} onClick={props.onDismiss} />}
    </StyledDiv>
  );
}

const StyledDiv = styled(Flex).withConfig({ shouldForwardProp })<CalloutProps>`
  padding: ${(props) => props.theme.callout.padding};

  // if the callout div isn't large enough to fit it's content then we'll
  // just have to hide it because the callout isn't designed for scrolling
  // and "visible" will make the content overflow out of the callout panel.
  overflow: hidden;

  ${(props) => typography(props.theme.callout.font)}
  ${(props) => border(props.theme.callout.border)}
  ${(props) => intentStyle(props.theme.callout.intents, props.intent)}
`;

function intentStyle(styles: Theme['callout']['intents'], intent: Intent = Intent.None) {
  const style = styles[intent];
  return css`
    color: ${style.fg};
    background-color: ${style.bg};
    border: ${border(style.border)};
  `;
}

const StyledCircle = styled.div.withConfig({ shouldForwardProp })<{ intent?: Intent }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 9999px;
  width: 48px;
  height: 48px;
  background: ${({ intent, theme }) => theme.callout.intents[intent ?? Intent.None].circleBg};
`;
