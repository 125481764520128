import { useMemo, useSyncExternalStore } from 'react';

export function useMap<K, V>(): Map<K, V> {
  const map = useMemo(() => new ObservableMap<K, V>(), []);

  return useSyncExternalStore(
    (subscriber) => {
      map.addChangeListener(subscriber);
      return () => {
        map.removeChangeListener(subscriber);
      };
    },
    () => map
  );
}

type ChangeListener = () => void;

class ObservableMap<T, U> extends Map<T, U> {
  private changeListeners = new Set<ChangeListener>();

  set(key: T, value: U): this {
    super.set(key, value);
    this.notifyChange();
    return this;
  }

  delete(key: T): boolean {
    const deletedValue = super.delete(key);
    this.notifyChange();
    return deletedValue;
  }

  clear(): void {
    super.clear();
    this.notifyChange();
  }

  addChangeListener(listener: ChangeListener): void {
    this.changeListeners.add(listener);
  }

  removeChangeListener(listener: ChangeListener): void {
    this.changeListeners.delete(listener);
  }

  private notifyChange(): void {
    for (const listener of this.changeListeners) {
      listener();
    }
  }
}
