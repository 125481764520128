import { useState } from 'react';
import { useEvent } from './useEvent';

export type AsyncFunction = (...args: any[]) => Promise<unknown>;

export interface AsyncEventHook<T extends AsyncFunction> {
  callback: T;
  isFetching: boolean;
  error: unknown | undefined;
  dismissError: () => void;
}

export function useAsyncEvent<T extends AsyncFunction>(callback: T): AsyncEventHook<T> {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<unknown>(undefined);

  const fetch = useEvent(async (...params: any[]) => {
    setFetching(true);
    setError(undefined);
    try {
      return await callback(...params);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setFetching(false);
    }
  });

  const dismissError = useEvent(() => {
    setError(undefined);
  });

  return {
    callback: fetch as T,
    isFetching: fetching,
    error: error,
    dismissError: dismissError,
  };
}
