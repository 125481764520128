import isPropValid from '@emotion/is-prop-valid';

// Version 6 of styled-components removed the default prop filtering
// which some of our UI components relied on to prevent styling props
// from rendering into the DOM.
//
// The prop filtering implementation that styled-components used was actually
// just the @emotion/is-prop-valid library which filters props by name (an allow list of prop names).
//
// Our existing components reuse this forwarding filter to avoid breaks.
// This is done using `.withConfig({ shouldForwardProp })` on the styled component.
//
// https://styled-components.com/releases#v6.0.0%20beta%202
// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
export const shouldForwardProp = isPropValid;
