import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { springDefault } from '../Animation/DefaultAnimations';
import { colors } from '../Color/Colors';
import { Flex } from '../Flex/Flex';
import { shouldForwardProp } from '../Utils/shouldForwardProp';
import { DrawerContext } from './DrawerContext';

interface DrawerContentProps {
  children?: React.ReactNode;
}

export function DrawerContent(props: DrawerContentProps) {
  const ctx = useContext(DrawerContext);

  return (
    <StyledDrawerContent collapse={ctx.collapse}>
      <LayoutGroup>
        <AnimatePresence>
          {!ctx.collapse && (
            <MotionFlex align="stretch" animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={springDefault}>
              {props.children}
            </MotionFlex>
          )}
        </AnimatePresence>
      </LayoutGroup>
    </StyledDrawerContent>
  );
}

const StyledDrawerContent = styled.div.withConfig({ shouldForwardProp })<{ collapse?: boolean }>`
  grid-area: content;
  background-color: ${(props) => props.theme.drawer.bg};
  overflow-y: auto;
  scrollbar-color: ${colors.blue_700} ${(props) => props.theme.drawer.bg};
`;

const MotionFlex = motion(Flex);
