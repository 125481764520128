import { RuleSet, css } from 'styled-components';

/**
 * screens is a constant that represents the screen sizes for responsive
 * breakpoints.
 *
 * The choice of pixel widths was informed by the Bootstrap 5.0 library.
 *
 * The "xs" width must be "0" because media queries render styles using "min-width"
 * and the smallest screensize should be applicable.
 */
export const screens = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export type ScreenSize = keyof typeof screens;

/**
 * Breakpoints represents style values for different screen sizes.
 *
 * For example `Breakpoints<string>` might be used to allow a "color"
 * to be configured at different screen sizes.
 *
 * The `xs` size is always required as it's the base setting for the
 * responsive style; the other sizes will then override it as the
 * screen size of the device/page increases.
 */
export type Breakpoints<T> = T | ResponsiveBreakpoints<T>;

export type ResponsiveBreakpoints<T> = {
  /**
   * 0px -> 576px
   */
  xs: T;
  /**
   * 576px -> 768px
   */
  sm?: T;
  /**
   * 768 -> 992px
   */
  md?: T;
  /**
   * 992px -> 1200px
   */
  lg?: T;
  /**
   * 1200px -> 1400px
   */
  xl?: T;
  /**
   * 1400px -> inf
   */
  xxl?: T;
};

function isResponsiveBreakpoints<T>(breakpoints: Breakpoints<T>): breakpoints is ResponsiveBreakpoints<T> {
  if (breakpoints && typeof breakpoints === 'object' && Object.hasOwn(breakpoints, 'xs')) {
    return true;
  }
  return false;
}

/**
 * This function resolves missing breakpoint value configuration
 * by setting any missing breakpoint values to the next available breakpoint.
 */
export function resolveResponsiveBreakpoints<T>(breakpoints: ResponsiveBreakpoints<T>) {
  const { xs, sm, md, lg, xl, xxl } = breakpoints;
  return {
    xs: xs,
    sm: sm ?? xs,
    md: md ?? sm ?? xs,
    lg: lg ?? md ?? sm ?? xs,
    xl: xl ?? lg ?? md ?? sm ?? xs,
    xxl: xxl ?? xl ?? lg ?? md ?? sm ?? xs,
  };
}

/**
 * breakpointsMixing is a helper function that takes a set of values (breakpoints) that
 * represents style configuration for different screen sizes and produces a responsive style sheet
 * for a component.
 *
 * the breakpoint values will be passed to the `rule` callback which generates the required
 * css styles.
 *
 * @param breakpoints the breakpoint values for generating responsive styles
 * @param rule a callback invoked with the configured breakpoint value returning css`` styles
 * @returns
 */
export function breakpointsMixin<T>(breakpoints: Breakpoints<T> | undefined, rule: (value: T) => RuleSet): RuleSet {
  // if there's no input value then don't call the css rule at all.
  if (breakpoints === undefined) {
    return css``;
  }

  // if the configured breakpoints is just a single value
  // then we'll just render the single css rule to avoid
  // a set of redundant media queries.
  if (!isResponsiveBreakpoints(breakpoints)) {
    return css`
      ${rule(breakpoints)}
    `;
  }

  // if we have a set of configured breakpoints we'll
  // resolve the configuration to a full set of screen sizes
  // and then render the set of media queries
  const values = resolveResponsiveBreakpoints(breakpoints);
  return css`
    @media (min-width: ${screens.xs}px) {
      ${rule(values.xs)}
    }
    @media (min-width: ${screens.sm}px) {
      ${rule(values.sm)}
    }
    @media (min-width: ${screens.md}px) {
      ${rule(values.md)}
    }
    @media (min-width: ${screens.lg}px) {
      ${rule(values.lg)}
    }
    @media (min-width: ${screens.xl}px) {
      ${rule(values.xl)}
    }
    @media (min-width: ${screens.xxl}px) {
      ${rule(values.xxl)}
    }
  `;
}
