import React from 'react';
import { IconButton } from '../../Buttons/IconButton';
import { Select } from '../../Forms/Select/Select';
import { ArrowLeftIcon, ArrowRightIcon } from '../../Icon/Icons';
import { Spacer } from '../../Spacer/Spacer';
import { Text } from '../../Typography/Typography';
import { pageSizeOptions } from './PaginationHook';

export interface PaginationControlsProps {
  /**
   * CurrentPage given as a 0-based page number (i.e. the first page is currentPage=0).
   * The pagination controls will visually display this value as a 1-based page number
   * given non-programmers usually prefer this.
   */
  currentPage: number;
  /**
   * The current page size that the table is using.
   */
  pageSize: number;
  /**
   * TotalRows is an optional input that will be used to display the total number
   * of rows the table represents.
   * This input can be ignored if you're using a paginated API that does not support
   * a total count feature.
   */
  totalRows?: number;
  /**
   * OnNextPage is an optional callback that will enable the "next page" button control
   * when provided.
   */
  onNextPage?: () => void;
  /**
   * OnPreviouspage is an optional callback that will enable the "previous page" button control
   * when provided.
   */
  onPreviousPage?: () => void;
  /**
   * OnPageSizeChange is an optional callback that will enable the "page size" selection control
   * when provided.
   */
  onPageSizeChange?: (next: number) => void;
}

export function PaginationControls(props: PaginationControlsProps) {
  return (
    <Spacer horizontal gap={1} align="center">
      <div>
        <Text>Showing page {props.currentPage + 1}</Text>
        {props.totalRows && <Text> of {Math.ceil(props.totalRows / props.pageSize)}</Text>}
      </div>
      <Spacer horizontal gap={1} align="center">
        {props.onPageSizeChange && (
          <Select
            name="pagesize"
            value={props.pageSize}
            options={pageSizeOptions}
            onChange={props.onPageSizeChange}
            autoWidth
          />
        )}

        {props.onPreviousPage && (
          <IconButton
            icon={ArrowLeftIcon}
            disabled={props.currentPage <= 0}
            onClick={() => {
              if (props.currentPage > 0) {
                props.onPreviousPage!();
              }
            }}
          />
        )}

        {props.onNextPage && <IconButton icon={ArrowRightIcon} onClick={props.onNextPage} />}
      </Spacer>
    </Spacer>
  );
}
