import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { css, styled } from 'styled-components';
import { useClickAway } from '../Hooks/useClickAway';
import { useEvent } from '../Hooks/useEvent';
import { useHotkey } from '../Hooks/useHotkey';
import { OverlayLayer } from '../Overlay/OverlayProvider';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface PopoverProps {
  popover?: React.ReactNode | ((props: PopoverContentProps) => React.ReactNode);
  children?: React.ReactNode;
  hover?: boolean;
  styleCursor?: boolean;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | 'top'
    | 'bottom'
    | 'right'
    | 'left';
  static?: boolean;

  // controlled component props
  open?: boolean;
  onOpen?: (event?: React.SyntheticEvent | Event) => void;
  onDismiss?: (event?: React.SyntheticEvent | Event) => void;
}

export interface PopoverContentProps {
  open: boolean;
}

export function Popover(props: PopoverProps) {
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const [popover, setPopover] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(anchor, popover, {
    placement: props.placement ?? 'auto',
  });

  // this component can be controlled or uncontrolled.
  // if the "open", "onOpen" and "onDismiss" props aren't
  // provided then we use internal state.
  const [_isOpen, _setOpen] = useState(false);

  const open = props.open ?? _isOpen;

  const onOpen = useEvent((event?: React.SyntheticEvent) => {
    if (props.onOpen) {
      props.onOpen(event);
    } else {
      _setOpen(true);
    }
  });

  const onDismiss = useEvent((event?: React.SyntheticEvent) => {
    if (props.onDismiss) {
      props.onDismiss(event);
    } else {
      _setOpen(false);
    }
  });

  const toggle = useEvent((event: React.SyntheticEvent) => {
    if (!open) {
      onOpen(event);
    } else {
      onDismiss(event);
    }
  });

  useClickAway([anchor, popover], onDismiss);

  useHotkey('Escape', () => onDismiss(), []);

  const onKeyDown = useEvent((event: React.KeyboardEvent<any>) => {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.stopPropagation();
      onOpen();
    }
  });

  return (
    <>
      <StyledPopoverButton
        ref={setAnchor}
        onMouseDown={!props.hover ? leftClick(toggle) : undefined}
        onMouseEnter={props.hover ? onOpen : undefined}
        onMouseLeave={props.hover ? onDismiss : undefined}
        onKeyDown={onKeyDown}
        styleCursor={props.styleCursor ?? true}
      >
        {props.children}
      </StyledPopoverButton>
      {(open || props.static) && (
        <OverlayLayer>
          <StyledPopoverPanel
            {...attributes.popper}
            style={styles.popper}
            ref={setPopover}
            onMouseEnter={props.hover ? onOpen : undefined}
            onMouseLeave={props.hover ? onDismiss : undefined}
          >
            {props.popover instanceof Function ? props.popover({ open }) : props.popover}
          </StyledPopoverPanel>
        </OverlayLayer>
      )}
    </>
  );
}

const StyledPopoverButton = styled.div.withConfig({ shouldForwardProp })<{ styleCursor: boolean }>`
  position: relative;
  width: fit-content;

  ${(props) =>
    props.styleCursor &&
    css`
      * {
        cursor: pointer;
      }
    `}
`;

const StyledPopoverPanel = styled.div`
  padding: 0.5rem 0;
  z-index: 50;
`;

function leftClick(callback: (event: React.MouseEvent<Element>) => void) {
  return (event: React.MouseEvent) => {
    if (event.button === 0) {
      callback(event);
    }
  };
}
