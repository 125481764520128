import { css, styled } from 'styled-components';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface ImgProps {
  width?: Breakpoints<number>;
  height?: Breakpoints<number>;
}

export const Img = styled.img.withConfig({ shouldForwardProp: customShouldForwardProp })<ImgProps>`
  ${(props) =>
    props.width &&
    breakpointsMixin(
      props.width,
      (width) => css`
        width: ${width}px;
      `
    )}

  ${(props) =>
    props.height &&
    breakpointsMixin(
      props.height,
      (height) => css`
        height: ${height}px;
      `
    )}
`;

// This function is used to filter out the width/height prop so that
// styled components won't pass it to the html element.
// This is done because width/height can be set with responsive breakpoints
// which is an object causing "[Object object]" to be passed to the html.
// We don't yet have a smarter way to resolve the breakpoints in JS (only css).
function customShouldForwardProp(prop: string): boolean {
  if (prop === 'width') {
    return false;
  }
  if (prop === 'height') {
    return false;
  }
  return shouldForwardProp(prop);
}
