import { Metadata } from './Rpc';

export enum Code {
  /**
   * Ok, a successful rpc response
   */
  OK = 0,
  /**
   * Canceled, usually be the user
   */
  Canceled = 1,
  /**
   * Unknown error
   */
  Unknown = 2,
  /**
   * Argument invalid regardless of system state
   */
  InvalidArgument = 3,
  /**
   * Operation expired, may or may not have completed.
   */
  DeadlineExceeded = 4,
  /**
   * Entity not found.
   */
  NotFound = 5,
  /**
   * Entity already exists.
   */
  AlreadyExists = 6,
  /**
   * Operation not authorized.
   */
  PermissionDenied = 7,
  /**
   * Quota exhausted.
   */
  ResourceExhausted = 8,
  /**
   * Argument invalid in current system state.
   */
  FailedPrecondition = 9,
  /**
   * Operation aborted.
   */
  Aborted = 10,
  /**
   * Out of bounds, use instead of FailedPrecondition.
   */
  OutOfRange = 11,
  /**
   * Operation not implemented or disabled.
   */
  Unimplemented = 12,
  /**
   * Internal error, reserved for "serious errors".
   */
  Internal = 13,
  /**
   * Unavailable, client should back off and retry.
   */
  Unavailable = 14,
  /**
   * Unrecoverable data loss or corruption.
   */
  DataLoss = 15,
  /**
   * Request isn't authenticated.
   */
  Unauthenticated = 16,
}

/**
 * GrpcWebError is our standard grpc web error object.
 * Different grpc web javascript libraries have different error objects
 * and sometimes no standard error object.
 * Over time we may need to change to a new grpc client library and so this
 * type allows us to safely write frontend logic that catches rpc errors
 * against a standard object type into the future.
 * The GrpcWebChannel must implement the required mapping from the grpc library's
 * error object to this one.
 */
export class GrpcWebError extends Error {
  constructor(
    message: string,
    public code: Code,
    public metadata: Metadata
  ) {
    super(message);
    this.name = 'GrpcWebError';
  }
}

/**
 * IsGrpcError is a type guard to check if an unknown value is
 * a GrpcWebError.
 *
 * It's preferred to use this function over other runtime type checks.
 *
 * @param error an unknown value (may possibly be undefined or null)
 * @returns true when the value is a GrpcWebError object
 */
export function isGrpcError(error: unknown): error is GrpcWebError {
  if (error instanceof GrpcWebError) {
    return true;
  }
  return false;
}

export function tryGetTraceId(error: unknown): string | undefined {
  if (isGrpcError(error)) {
    return error.metadata?.get('x-trace-id') || undefined;
  }

  return undefined;
}
