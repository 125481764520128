import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { DrawerContext } from './DrawerContext';

export function DrawerDivider() {
  const ctx = useContext(DrawerContext);

  if (ctx.collapse) {
    return null;
  }

  return <StyledDrawerDivider />;
}

const StyledDrawerDivider = styled.div`
  margin: 10px 16px;
  height: 1px;
  background-color: #dbdbdb;
`;
