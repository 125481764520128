import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { styled } from 'styled-components';
import { IconButton } from '../Buttons/IconButton';
import { useToggle } from '../Hooks/useToggle';
import { ChevronDownIcon, ChevronUpIcon } from '../Icon/Icons';
import { Spacer } from '../Spacer/Spacer';
import { Title } from '../Typography/Typography';

export interface AccordionProps {
  title: string;
  defaultOpen?: boolean;
  children?: React.ReactNode;
}

export function Accordion(props: AccordionProps) {
  const [open, toggleOpen] = useToggle(props.defaultOpen ?? false);

  return (
    <Spacer justify="stretch">
      <StyledHeader padding={1} gap={1} horizontal align="center" justify="space-between" onClick={toggleOpen}>
        <Title size="h4">{props.title}</Title>
        <IconButton icon={open ? ChevronUpIcon : ChevronDownIcon} />
      </StyledHeader>
      <AnimatePresence initial={false}>
        {open && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto' },
              collapsed: { height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
            style={{ overflow: 'hidden' }}
          >
            <StyledChildren padding={1} justify="stretch">
              {props.children}
            </StyledChildren>
          </motion.div>
        )}
      </AnimatePresence>
    </Spacer>
  );
}

const StyledHeader = styled(Spacer)`
  cursor: pointer;

  :hover {
    background-color: hsl(0, 0%, 90%);
  }
`;

const StyledChildren = styled(Spacer)``;
