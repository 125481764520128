import { first } from 'lodash-es';
import React from 'react';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { Spinner } from '../Loading/Spinner';

export interface ContentLoadingIndicatorProps {
  loading: LoadingState | LoadingState[];
  children?: React.ReactNode;
}

export function ContentLoadingIndicator(props: ContentLoadingIndicatorProps) {
  const loading = anyLoading(props.loading);

  if (loading.isFetching) {
    return <Spinner />;
  }

  if (loading.error) {
    return <ErrorMessage intent="warning" title="Something went wrong" error={loading.error} />;
  }

  return props.children ?? null;
}

type LoadingState = {
  isFetching?: boolean;
  error?: unknown;
};

export function anyLoading(loading: LoadingState | LoadingState[]): LoadingState {
  if (Array.isArray(loading)) {
    return {
      isFetching: loading.some((l) => l.isFetching),
      error: first(loading.map((l) => l.error).filter((error) => error !== undefined)),
    };
  }

  return loading;
}
