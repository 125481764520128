import React from 'react';
import { css, styled } from 'styled-components';
import { IconButton } from '../Buttons/IconButton';
import { brandColors, colors, functionalColors } from '../Color/Colors';
import { XMarkIcon } from '../Icon/Icons';
import { Intent } from '../Intent/Intent';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { border } from '../Theme/Mixins';
import { Theme } from '../Theme/Types';
import { TypeScale, TypeScaleSize, createTypeScale } from '../Typography/TypeScale';
import { Text } from '../Typography/Typography';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface ChipProps {
  label: string;
  color?: ChipColor;
  scale?: Breakpoints<TypeScaleSize>;
  onDismiss?: () => void;
}

export type ChipColor = 'grey' | 'green' | 'red' | 'light-blue' | 'blue' | 'dark-blue' | 'yellow' | 'white';

export function Chip(props: ChipProps) {
  const { label, color, onDismiss } = props;
  return (
    <StyledChip color={color} scale={props.scale}>
      <Text>{label}</Text>
      {onDismiss && <StyledIcon icon={XMarkIcon} onClick={onDismiss} />}
    </StyledChip>
  );
}

const paddingScale = createTypeScale(TypeScale.MinorThird);

const fontScale = createTypeScale(TypeScale.MinorSecond);

const StyledChip = styled.div.withConfig({ shouldForwardProp })<{
  color?: ChipProps['color'];
  scale?: Breakpoints<TypeScaleSize>;
}>`
  display: inline-flex;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;

  /* responsive font-size that uses the "scale" prop (typescale) */
  ${({ scale }) =>
    breakpointsMixin(
      scale ?? 0,
      (scale) => css`
        ${Text} {
          font-size: ${fontScale(scale, '12px')};
          font-weight: 500;
        }
      `
    )}

  /* responsive padding that uses the "scale" prop (typescale) */
  ${({ scale }) =>
    breakpointsMixin(
      scale ?? 0,
      (scale) => css`
        padding: ${paddingScale(scale, '4px')} ${paddingScale(scale, '20px')};
      `
    )}

  ${(props) => chipColorStyle(props.color)}
`;

const StyledIcon = styled(IconButton)`
  color: white;
  font-weight: bold;
  background-color: ${colors.slate_500};
  border-radius: 50%;
  padding: 2px;
  height: 14px;
  width: 14px;
  cursor: pointer;
`;

function chipColorStyle(color: ChipProps['color'] = 'grey') {
  if (color === 'green') {
    return css`
      color: ${colors.slate_900};
      background-color: ${functionalColors.greenBackground};
      border-color: ${functionalColors.green};
    `;
  }

  if (color === 'red') {
    return css`
      color: white;
      background-color: ${functionalColors.red};
      border-color: ${functionalColors.red};
    `;
  }

  if (color === 'light-blue') {
    return css`
      color: ${colors.slate_900};
      background-color: ${brandColors.blueLighter};
      border-color: ${brandColors.blueLight};
    `;
  }

  if (color === 'blue') {
    return css`
      color: ${colors.slate_900};
      background-color: ${brandColors.blueLight};
      border-color: ${brandColors.blueLight};
    `;
  }

  if (color === 'dark-blue') {
    return css`
      color: ${colors.slate_50};
      background-color: ${brandColors.blueDark};
      border-color: ${brandColors.blueDark};
    `;
  }

  if (color === 'yellow') {
    return css`
      color: ${colors.slate_900};
      background-color: ${functionalColors.yellowBackground};
      border-color: ${functionalColors.yellow};
    `;
  }

  if (color === 'white') {
    return css`
      color: ${colors.slate_900};
      background-color: white;
      border-color: white;
    `;
  }

  // default is for 'grey'
  return css`
    color: black;
    background-color: ${colors.gray_100};
    border-color: ${colors.gray_200};
  `;
}
