import { css, styled } from 'styled-components';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface FlexProps {
  gap?: number;
  horizontal?: Breakpoints<boolean>;
  justify?: 'left' | 'right' | 'center' | 'space-between' | 'stretch' | 'space-around';
  align?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
  alignSelf?: 'flex-start' | 'center' | 'flex-end' | 'stretch';
  padding?: number;
  wrap?: boolean;
  flex?: number;
  shrink?: number;
}

// Flex defaults to a vertial layout which may be confusing at first
// for most web devs given display=flex defaults to row layout.
// I think this should be renamed to `Stack` which matches some other UI
// libraries i've found while researching.
export const Flex = styled.div.withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop) && !['wrap'].includes(prop),
})<FlexProps>`
  display: flex;
  gap: ${(props) => props.gap ?? 0}rem;

  ${(props) =>
    breakpointsMixin(
      props.horizontal ?? false,
      (horizontal) => css`
        flex-direction: ${horizontal ? 'row' : 'column'};
      `
    )}

  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  justify-content: ${(props) => props.justify ?? 'flex-start'};
  align-items: ${(props) => props.align ?? 'flex-start'};
  align-self: ${(props) => props.alignSelf ?? 'auto'};
  padding: ${(props) => props.padding ?? 0}rem;
  flex: ${(props) => props.flex ?? '0 1 auto'};

  ${(props) => props.shrink !== undefined && `flex-shrink: ${props.shrink};`}
`;
