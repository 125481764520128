import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { springSlow } from '../Animation/DefaultAnimations';
import { IconButton } from '../Buttons/IconButton';
import { colors } from '../Color/Colors';
import { Flex } from '../Flex/Flex';
import { ChevronDoubleRightIcon } from '../Icon/Icons';
import { Text } from '../Typography/Typography';
import { DrawerContext } from './DrawerContext';

export function DrawerCollapser() {
  const ctx = useContext(DrawerContext);

  return (
    <StyledClickable onClick={ctx.toggle}>
      <StyledDrawerCollapser horizontal gap={0.5} align="center">
        <motion.div animate={{ rotate: ctx.collapse ? 180 : 0 }} transition={springSlow}>
          <IconButton icon={ChevronDoubleRightIcon} />
        </motion.div>

        {!ctx.collapse && (
          <Text muted nowrap>
            collapse
          </Text>
        )}
      </StyledDrawerCollapser>
    </StyledClickable>
  );
}

const StyledDrawerCollapser = styled(Flex)`
  grid-area: collapser;
  padding: 0 16px;
  height: 56px;
  border-top: 1px solid ${colors.blue_700};
  background-color: ${(props) => props.theme.drawer.bg};
`;

const StyledClickable = styled.div`
  cursor: pointer;
  user-select: none;
`;
