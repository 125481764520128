import { css } from 'styled-components';

/*
The purpose of this file is to export css that resets various
element styles back to their browser defaults.

This exists because we must undo various global css rules
that exist in ep-web.

These resets are used in uikit components only.
*/

export const buttonCSSReset = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const checkboxCSSReset = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
