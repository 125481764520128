import { isEqual } from 'lodash-es';
import { useRef } from 'react';

export function useEqualityMemo<T>(value: T): T {
  const ref = useRef<T>(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
