import { motion } from 'framer-motion';
import React from 'react';
import { css, styled } from 'styled-components';
import { springDefault } from '../Animation/DefaultAnimations';
import { Flex } from '../Flex/Flex';
import { Icon } from '../Icon/Icon';
import { IconProp } from '../Icon/Icons';
import { isNullOrWhitespace } from '../Text';
import { Text } from '../Typography/Typography';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface DrawerItemProps {
  label?: string;
  children?: React.ReactNode;
  icon?: IconProp;
  active?: boolean;
  onClick?: () => void;
}

export function DrawerItem(props: DrawerItemProps) {
  return (
    <StyledDrawerItem horizontal gap={0.5} align="center" active={props.active} onClick={props.onClick}>
      {/* if the drawer item has an icon render it */}
      {props.icon && <Icon icon={props.icon} size={1.3} />}

      {/* if the drawer item has a label show it */}
      {!isNullOrWhitespace(props.label) && <Text nowrap>{props.label}</Text>}

      {/* if the drawer item has any more children then render them */}
      {/* children aren't ideal because they may break style and layout */}
      {props.children}

      {props.active && <StyledActiveIndicator layoutId="active-indicator" transition={springDefault} />}
    </StyledDrawerItem>
  );
}

export const StyledDrawerItem = styled(Flex).withConfig({ shouldForwardProp })<Partial<DrawerItemProps>>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.drawer.paddings.vertical} ${(props) => props.theme.drawer.paddings.horizontal};
  color: ${(props) => (props.active ? props.theme.drawer.item.fg : props.theme.drawer.item.fg)};
  background-color: ${(props) => props.theme.drawer.item.bg};

  // Drawer items are often clickable elements so we disable text
  // selection to avoid highlighting text when the user clicks.
  user-select: none;
  -webkit-user-drag: none;

  & + & {
    margin-top: 2px;
  }

  // the text becomes bold when active.
  // putting a slight transition on that change here.
  transition: font-weight 0.2 ease-in-out;

  ${(props) =>
    props.active &&
    css`
      position: relative;
      color: ${props.theme.brand.colors.blueLight};
      background-color: ${props.theme.drawer.item.bg};
      font-weight: 700;
    `}

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;

const StyledActiveIndicator = motion(styled.div`
  border-left: solid 4px ${(props) => props.theme.brand.colors.blueLight};
  width: 0;
  height: 100%;
  left: 0;
  position: absolute;
  z-index: 9999;
`);
