import { styled } from 'styled-components';
import { colors } from '../Color/Colors';

export interface SpinnerProps {
  size?: number;
}

function sizePx(value: number | undefined) {
  return (value ?? 1) * 16;
}

export const Spinner = styled.div<SpinnerProps>`
  border-radius: 50%;
  width: ${(props) => sizePx(props.size)}px;
  height: ${(props) => sizePx(props.size)}px;

  position: relative;
  text-indent: -9999em;
  border-top: ${(props) => sizePx(props.size) / 4}px solid ${colors.slate_300};
  border-right: ${(props) => sizePx(props.size) / 4}px solid ${colors.slate_300};
  border-bottom: ${(props) => sizePx(props.size) / 4}px solid ${colors.slate_300};
  border-left: ${(props) => sizePx(props.size) / 4}px solid ${colors.slate_500};
  transform: translateZ(0);
  animation: spin 0.8s infinite linear;

  &:after {
    border-radius: 50%;
    width: ${(props) => sizePx(props.size)}px;
    height: ${(props) => sizePx(props.size)}px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
