import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { DrawerContext } from './DrawerContext';

export function DrawerSpacer() {
  const ctx = useContext(DrawerContext);

  if (ctx.collapse) {
    return null;
  }

  return <StyledSpacer />;
}

const StyledSpacer = styled.div`
  margin-top: ${(props) => props.theme.drawer.paddings.vertical};
  margin-bottom: ${(props) => props.theme.drawer.paddings.vertical};
  height: 0;
  background: ${(props) => props.theme.drawer.bg};
`;
