import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { Box } from '../Box/Box';
import { IconButton } from '../Buttons/IconButton';
import { Bars3Icon } from '../Icon/Icons';
import { DrawerContext } from './DrawerContext';

export interface DrawerHeaderProps {
  children?: React.ReactNode;
}

export function DrawerHeader(props: DrawerHeaderProps) {
  const ctx = useContext(DrawerContext);

  if (ctx.collapse) {
    return (
      <Box padding={1}>
        <IconButton icon={Bars3Icon} onClick={ctx.toggle} />
      </Box>
    );
  }

  return <StyledDrawerHeader>{props.children}</StyledDrawerHeader>;
}

const StyledDrawerHeader = styled.div`
  grid-area: header;
  color: ${(props) => props.theme.drawer.header.fg};
  background-color: ${(props) => props.theme.drawer.header.bg};

  display: flex;
  align-items: center;

  padding: ${(props) => props.theme.drawer.paddings.horizontal};
`;
