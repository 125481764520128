import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { DrawerContext } from './DrawerContext';

export interface DrawerFooterProps {
  children?: React.ReactNode;
}

export function DrawerFooter(props: DrawerFooterProps) {
  const ctx = useContext(DrawerContext);

  if (ctx.collapse) {
    return null;
  }

  return <StyledDrawerFooter>{props.children}</StyledDrawerFooter>;
}

const StyledDrawerFooter = styled.div`
  grid-area: footer;
  padding: 16px ${(props) => props.theme.drawer.paddings.horizontal};
`;
