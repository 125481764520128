import { useCallback, useMemo, useState } from 'react';
import { useEvent } from '../../Hooks/useEvent';
import { RowID } from '../DataTableContracts';

interface SelectableHook {
  toggleAll: () => void;
  toggle: (id: RowID) => void;
  isSelected: (id: RowID) => boolean;
  isAllSelected: () => boolean;
}

export function useSelectable(): SelectableHook {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<Record<RowID, boolean>>({});

  const toggle = useEvent((id: RowID) => {
    setSelected((current) => {
      const next = { ...current };
      if (current[id] === true) {
        delete next[id];
      } else {
        next[id] = true;
      }
      return next;
    });
  });

  const toggleAll = useEvent(() => {
    setSelected({});
    setSelectAll((current) => !current);
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: linter doesn't understand Record reference handling.
  const isSelected = useCallback(
    (id: RowID) => {
      if (selectAll) {
        return true;
      }
      return !!selected[id];
    },
    [selectAll, selected]
  );

  const isAllSelected = useCallback(() => selectAll, [selectAll]);

  return useMemo(
    () => ({ toggleAll, toggle, isSelected, isAllSelected }),
    [toggleAll, toggle, isSelected, isAllSelected]
  );
}
