import React from 'react';
import { styled } from 'styled-components';
import { IconButton } from '../Buttons/IconButton';
import { colors } from '../Color/Colors';
import { Grid, GridSkip } from '../Grid/Grid';
import { XMarkIcon } from '../Icon/Icons';
import { Intent, IntentIcon } from '../Intent/Intent';
import { TimeoutBar } from '../Loading/TimeoutBar';
import { Paper, PaperPadding } from '../Paper/Paper';
import { Text } from '../Typography/Typography';

export interface ToastProps {
  key?: string;
  title: string;
  description?: string;
  subtext?: string;
  intent?: Intent;
  timeout?: number | Date;
  onDismiss?: () => void;
}

export function Toast(props: ToastProps) {
  return (
    <StyledToast elevation={1} nopad intent={props.intent}>
      <PaperPadding>
        <Grid cols="auto 1fr auto" rowGap={0.5} colGap={1} align="center">
          <IntentIcon intent={props.intent} />

          <Text>{props.title}</Text>

          {props.onDismiss ? <IconButton icon={XMarkIcon} onClick={props.onDismiss} /> : <GridSkip />}

          {props.description && (
            <>
              <GridSkip />
              <Text scale={-1}>{props.description}</Text>
              <GridSkip />
            </>
          )}

          {props.subtext && (
            <>
              <GridSkip />
              <Text scale={-3} muted>
                {props.subtext}
              </Text>
              <GridSkip />
            </>
          )}
        </Grid>
      </PaperPadding>
      {props.timeout && <TimeoutBar intent={props.intent} seconds={props.timeout} onTimeout={props.onDismiss} />}
    </StyledToast>
  );
}

const StyledToast = styled(Paper)<{ intent?: Intent }>`
  position: relative;
  overflow: hidden;
  background-color: white;
  color: ${colors.slate_900};
`;
