import React, { useRef } from 'react';
import { styled } from 'styled-components';
import { useEffectOnce } from '../../Hooks/useEffectOnce';
import { Icon } from '../../Icon/Icon';
import { IconProp } from '../../Icon/Icons';
import { Text } from '../../Typography/Typography';
import { shouldForwardProp } from '../../Utils/shouldForwardProp';

export interface SelectOption<T> {
  key?: string | number;
  label: string;
  value: T;
  icon?: IconProp | React.ReactNode;
  iconRight?: IconProp | React.ReactNode;
}

interface OptionProps<T> {
  isSelected: boolean;
  isHovered: boolean;
  option: SelectOption<T>;
  onClick: () => void;
  onHover: () => void;
}

export function Option<T>(props: OptionProps<T>) {
  const ref = useRef<HTMLDivElement | null>(null);

  // scroll the option into view if it's selected
  useEffectOnce(() => {
    // the use of request animation frame is because react-popper
    // will display popup elements in the top left of the page for 1 frame
    // until it can calculate the correct position over the popover element.
    // these select options are used in the <Select /> component which
    // uses react-popper.
    // by requesting an animation frame we are able to wait 1 frame
    // and scroll when the popover is in the correct position
    requestAnimationFrame(() => {
      if (props.isSelected && ref.current) {
        ref.current.scrollIntoView({
          block: 'nearest',
        });
      }
    });
  }, [props.isSelected, ref]);

  // scroll the option into view if it's hovered
  useEffectOnce(() => {
    requestAnimationFrame(() => {
      if (props.isHovered && ref.current) {
        if (!ref.current.matches(':hover')) {
          ref.current.scrollIntoView({
            block: 'nearest',
          });
        }
      }
    });
  }, [props.isHovered, ref]);

  return (
    <StyledOption
      ref={ref}
      key={props.option.key}
      isSelected={props.isSelected}
      isHovered={props.isHovered}
      onClick={props.onClick}
      onMouseOver={props.onHover}
    >
      <IconOrContent icon={props.option.icon} />
      <Text>{props.option.label}</Text>
      <IconOrContent icon={props.option.iconRight} />
    </StyledOption>
  );
}

function IconOrContent(props: { icon: IconProp | React.ReactNode | undefined }) {
  // case for undefined
  if (!props.icon) {
    // empty element for the StyledOption's css grid layout
    return <span />;
  }

  // case for IconProp which is always a function component
  if (typeof props.icon === 'function') {
    return <Icon icon={props.icon} />;
  }

  // case for React.ReactNode
  return <>{props.icon}</>;
}

export const StyledOption = styled.div.withConfig({ shouldForwardProp })<{ isSelected: boolean; isHovered: boolean }>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.3rem;
  align-items: center;
  padding: 8px 16px;

  cursor: pointer;

  * {
    cursor: pointer !important;
  }

  color: ${(props) => (props.isSelected ? 'white' : 'black')};
  background-color: ${(props) => (props.isSelected ? '#1c61bb' : props.isHovered ? '#deebff' : 'white')};
`;
