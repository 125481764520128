import { createGlobalStyle } from 'styled-components';
import { typography } from './Mixins';
import { normalize } from './Normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }

  html {
    ${({ theme }) => typography(theme.body.font)}
  }

  body {
    ${({ theme }) => typography(theme.body.font)}
    background: ${(props) => props.theme.body.background};
  }

  a {
    ${({ theme }) => typography(theme.typography.text)};
    text-decoration: none;
  }
`;
