import React from 'react';
import { css, styled } from 'styled-components';
import { Flex } from '../Flex/Flex';
import { Text, Title } from '../Typography/Typography';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface DrawerPanelProps {
  label: string;
  description?: string;
  active?: boolean;
}

export function DrawerPanel(props: DrawerPanelProps) {
  return (
    <StyledDrawerPanel horizontal align="center" active={props.active}>
      <Flex gap={0.2}>
        <Title bold size="h6" nowrap>
          {props.label}
        </Title>
        {props.description && <Text scale={-1}>{props.description}</Text>}
      </Flex>
    </StyledDrawerPanel>
  );
}

const StyledDrawerPanel = styled(Flex).withConfig({ shouldForwardProp })<Partial<DrawerPanelProps>>`
  position: relative;
  display: flex;
  align-items: center;
  padding: calc(${(props) => props.theme.drawer.paddings.vertical} * 2)
    ${(props) => props.theme.drawer.paddings.horizontal};
  background-color: ${(props) => props.theme.drawer.panel.bg};

  ${Title}, ${Text} {
    color: ${(props) => props.theme.drawer.panel.fg};
  }

  // Drawer items are often clickable elements so we disable text
  // selection to avoid highlighting text when the user clicks.
  user-select: none;

  & + & {
    margin-top: 2px;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.brand.colors.blueLight};
      border-radius: 0;

      ${Title}, ${Text} {
        color: ${props.theme.brand.colors.blueDark};
      }
    `}
`;
