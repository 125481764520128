import React, { PropsWithChildren, useContext } from 'react';

export interface Context<TState> {
  state: TState;
}

export type AppContextProvider<TState> = React.ComponentType<PropsWithChildren<TState>>;

export type UseAppContext<TState> = () => TState;

/**
 * createAppContext can be used to create a global state for your application
 * or module within your application.
 *
 * createAppContext will create type-safe react context that can be used
 * by your react components.
 *
 * @deprecated
 */
export function createAppContext<TState>(): [AppContextProvider<Context<TState>>, UseAppContext<TState>] {
  const context = React.createContext<Context<TState>>({} as any);

  const Provider: AppContextProvider<Context<TState>> = (props) => {
    return <context.Provider value={props}>{props.children}</context.Provider>;
  };

  const useAppContext = () => {
    return useContext(context).state;
  };

  return [Provider, useAppContext];
}
