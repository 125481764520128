import { getContrast, lighten } from 'polished';
import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { css, styled } from 'styled-components';
import { colors } from '../Color/Colors';
import { useAsyncEvent } from '../Hooks/useAsyncEvent';
import { useAutoFocus } from '../Hooks/useAutoFocus';
import { Icon } from '../Icon/Icon';
import { IconProp } from '../Icon/Icons';
import { Intent } from '../Intent/Intent';
import { Spinner } from '../Loading/Spinner';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { border, typography } from '../Theme/Mixins';
import { Theme } from '../Theme/Types';
import { TypeScale, TypeScaleSize, createTypeScale } from '../Typography/TypeScale';
import { shouldForwardProp } from '../Utils/shouldForwardProp';
import { buttonCSSReset } from './CSSReset';
import { Ripple } from './Ripple';
import { StyledTextButton } from './StyledTextButton';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  intent?: Intent;
  variant?: ButtonVariant;
  isLoading?: boolean;
  fullWidth?: boolean;
  autoFocus?: boolean;
  /**
   * Primary is a short hand for setting intent="primary"
   */
  primary?: boolean;
  /**
   * Secondary is a short hand for settings intent="secondary"
   */
  secondary?: boolean;
  /**
   * Danger is a short hand for setting intent="danger"
   */
  danger?: boolean;
  /**
   * Warning is a short hand for setting intent="warning"
   */
  warning?: boolean;
  /**
   * Success is a short hand for setting intent="success"
   */
  success?: boolean;
  /**
   * light makes outlined buttons work on dark backgrounds
   */
  light?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  scale?: Breakpoints<TypeScaleSize>;
}

export type ButtonVariant = (typeof ButtonVariant)[keyof typeof ButtonVariant];
export const ButtonVariant = {
  Contained: 'contained',
  Outlined: 'outlined',
  Text: 'text',
} as const;

export function Button(props: ButtonProps) {
  let { children, onClick, intent, ...buttonProps } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  useAutoFocus(buttonRef, props.autoFocus);

  const form = useFormContext();

  const onClickWrapper = useAsyncEvent(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick && !isLoading && !props.disabled) {
      return onClick(event);
    }
  });

  const isLoading = form?.formState?.isSubmitting || props.isLoading || onClickWrapper.isFetching;

  if (props.primary) {
    intent = 'primary';
  } else if (props.secondary) {
    intent = 'secondary';
  } else if (props.warning) {
    intent = 'warning';
  } else if (props.danger) {
    intent = 'danger';
  } else if (props.success) {
    intent = 'success';
  }

  const content = (
    <>
      {props.iconLeft && <Icon icon={props.iconLeft} />}
      {children}
      {props.iconRight && <Icon icon={props.iconRight} />}
    </>
  );

  if (props.variant === ButtonVariant.Text) {
    return <StyledTextButton scale={props.scale}>{content}</StyledTextButton>;
  }

  return (
    <StyledButton
      {...buttonProps}
      ref={buttonRef}
      onClick={props.disabled || isLoading ? undefined : onClickWrapper.callback}
      intent={intent}
      isLoading={isLoading}
    >
      <Ripple />
      {isLoading && (
        <StyledSpinnerPosition>
          <StyledBackdrop />
          <Spinner />
        </StyledSpinnerPosition>
      )}

      {content}
    </StyledButton>
  );
}

// Below we're choosing a typescale for both padding and font-size.
// The padding uses a larger typescale so that the button has more
// space as the size increases.
// The font-size uses a smaller typescale so that the visual size
// doesn't become excessive as the size increases.
const paddingScale = createTypeScale(TypeScale.PerfectFourth);
const fontScale = createTypeScale(TypeScale.MajorSecond);

// exported for internal reference in css rules only
export const StyledButton = styled.button.withConfig({ shouldForwardProp })<ButtonProps>`
  ${buttonCSSReset}

  ${(props) => typography(props.theme.button.font)}
  ${(props) => border(props.theme.button.border)}

  position: relative;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
  overflow: hidden;
  transition: background-color 80ms linear;

  ${(props) => intentStyle(props.theme.button.intents, props.intent, props.variant, props.light)}

  /* responsive font-size that uses the "scale" prop (typescale) */
  ${({ scale }) =>
    breakpointsMixin(
      scale ?? 0,
      (scale) => css`
        font-size: ${fontScale(scale, '14px')};
      `
    )}

  /* responsive padding that uses the "scale" prop (typescale) */
  ${({ scale }) =>
    breakpointsMixin(
      scale ?? 0,
      (scale) => css`
        padding: ${paddingScale(scale, '8px')} ${paddingScale(scale, '16px')};
      `
    )}

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      flex: 1;
    `}

  &:hover {
    ${(props) =>
      (props.variant === ButtonVariant.Contained || props.variant === undefined) &&
      !props.isLoading &&
      !props.disabled &&
      css`
        background-color: ${lighten(0.1, props.theme.button.intents[props.intent ?? 'none'].bg)};

        ${
          props.theme.button.intents[props.intent ?? 'none'].border.color &&
          css`
          border-color: ${lighten(0.1, props.theme.button.intents[props.intent ?? 'none'].border.color!)};
        `
        }
      `}
  }

  &:focus,
  &:focus-visible {
    ${(props) =>
      !props.isLoading &&
      !props.disabled &&
      css`
        // needed to use !important because the outline was being ignored
        // due to a default firefox style.
        outline: 2px solid ${(props) => props.theme.intent.primary.bg} !important;
        outline-offset: -2px;
      `}
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${colors.slate_500};
      background-color: ${colors.slate_100};
      border-color: ${colors.slate_300};
    `}

  ${(props) =>
    props.isLoading &&
    css`
      cursor: progress;
    `}
`;

const StyledSpinnerPosition = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  border-radius: ${({ theme }) => theme.button.border.radius};
`;

function intentStyle(
  styles: Theme['button']['intents'],
  intent: Intent = Intent.None,
  variant: ButtonVariant = ButtonVariant.Contained,
  light = false
) {
  const style = styles[intent];

  switch (variant) {
    case ButtonVariant.Outlined:
      return css`
        color: ${light ? 'white' : bestContrastingColor('white', style.fg, style.bg)};
        background-color: 'white';
        ${border(style.border, light ? 'white' : undefined)};
      `;

    case ButtonVariant.Contained:
    default:
      return css`
        color: ${style.fg};
        background-color: ${style.bg};
        ${border(style.border)};
      `;
  }
}

function bestContrastingColor(bg: string, a: string, b: string): string {
  return getContrast(bg, a) >= getContrast(bg, b) ? a : b;
}
