import { RefObject, useEffect } from 'react';

export function useAutoFocus(ref: RefObject<HTMLElement>, enabled = false) {
  useEffect(() => {
    if (enabled && ref.current) {
      ref.current.focus();

      if (ref.current instanceof HTMLInputElement) {
        ref.current.select();
      }
    }
  }, [ref, enabled]);
}
