import FocusTrap from 'focus-trap-react';
import { AnimatePresence, motion, useIsPresent } from 'framer-motion';
import React, { useRef } from 'react';
import { styled } from 'styled-components';
import { springDefault, springSlow } from '../Animation/DefaultAnimations';
import { useEvent } from '../Hooks/useEvent';
import { useHotkey } from '../Hooks/useHotkey';
import { OverlayLayer } from '../Overlay/OverlayProvider';

export interface ModalProps {
  open: boolean;
  children?: React.ReactNode;
  onDismiss?: () => void;
  /**
   * If static is false (the default) then the modal's children
   * are only rendered when the modal is opened.
   * i.e. lazy rendering.
   *
   * A static modal will render it's children immediately
   * but will not make them visible until the modal is open.
   *
   * Lazy rendering is typically preferred but some use-cases
   * will require static modals; for example a form that has
   * some inputs inside a nested modal.
   */
  static?: boolean;
}

export function Modal(props: ModalProps) {
  const present = useIsPresent();

  const openAndPresent = props.open && present;

  const onClickAway = useEvent((event: React.SyntheticEvent<HTMLElement>) => {
    // Dismiss the modal if and only if the click event was on the full screen positioner
    // element itself and not from one of it's children elements.
    // Note: `event.target` is the element that triggered the event (e.g. the element that was clicked)
    // and `event.currentTarget` is the element that the event listener is attached to (the fullscreen positioner element).
    // If the target and currentTarget are the same then we clicked on the positioner and not a child element
    if (event.target === event.currentTarget) {
      event.preventDefault();
      props.onDismiss?.();
    }
  });

  const positionerRef = useRef<HTMLDivElement>(null);

  useHotkey(
    'Escape',
    (event) => {
      if (positionerRef.current && event.target instanceof Element) {
        if (positionerRef.current.contains(event.target)) {
          event.preventDefault();
          props.onDismiss?.();
        }
      }
    },
    [props.onDismiss]
  );

  return (
    <OverlayLayer>
      <FocusTrap
        active={openAndPresent}
        focusTrapOptions={{
          escapeDeactivates: false,
          returnFocusOnDeactivate: false,
          preventScroll: true,
          initialFocus: false,
        }}
      >
        <StyledModalContainer style={{ pointerEvents: openAndPresent ? 'all' : 'none' }}>
          <StyledBackdrop
            as={motion.div}
            animate={{ opacity: openAndPresent ? 1 : 0 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={springSlow}
          />
          <StyledPositioner ref={positionerRef} onClick={onClickAway}>
            <AnimatePresence>
              {(props.static || props.open) && (
                <StyledModalPanel
                  animate={openAndPresent ? 'open' : 'closed'}
                  variants={panelVariants}
                  initial="closed"
                  exit="closed"
                  transition={springDefault}
                >
                  {props.children}
                </StyledModalPanel>
              )}
            </AnimatePresence>
          </StyledPositioner>
        </StyledModalContainer>
      </FocusTrap>
    </OverlayLayer>
  );
}

const panelVariants = {
  closed: { opacity: 0, transform: 'scale(0.9) translateY(-30px)' },
  open: { opacity: 1, transform: 'scale(1) translateY(0px)' },
};

const StyledModalPanel = styled(motion.div)`
  transform-origin: bottom;
  max-height: 100%;
  max-width: 100%;

  &:focus {
    border: 2px solid ${(props) => props.theme.intent.primary.bg};
    outline: 1px solid ${(props) => props.theme.intent.primary.bg};
  }
`;

const StyledBackdrop = styled.div.attrs({ 'aria-hidden': true })`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  pointer-events: none;
`;

const StyledPositioner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const StyledModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
