import React, { useLayoutEffect, useMemo, useState } from 'react';
import { useAsync } from './useAsync';
import { useEvent } from './useEvent';

interface Bounds {
  height: number;
  width: number;
}

const defaultState: Bounds = Object.freeze({
  height: 0,
  width: 0,
});

// useRef requires a specific element type in typescript but
// we don't want to dictate what html element type is used
// or make the hook generic; so this is a pragmatic solution I guess.
export type AnyHtmlElement = any;

/**
 * useMeasure is a hook that uses the ResizeObserver API to measure
 * an HTML element returning it's size.
 *
 * @example
 *
 * const [ref, bounds] = useMeasure()
 *
 * //...
 *
 * <div ref={ref}>some content</div>
 */
export function useMeasure(): [React.Ref<AnyHtmlElement>, Bounds] {
  // The current html element we need to measure
  const [target, setTarget] = useState<HTMLElement>();

  // The current bounds measured from the target html element
  const [bounds, setBounds] = useState<Bounds>(defaultState);

  // A resize observer that we will attach to the target element.
  // The resize observer is a browser-native API that's able to notify
  // us that the element has changed size.
  const observer = useMemo(
    () =>
      new ResizeObserver(([entry]) => {
        const rect = entry.target.getBoundingClientRect();
        setBounds({
          height: rect.height,
          width: rect.width,
        });
      }),
    []
  );

  const ready = useAsync(async () => {
    await document.fonts.ready;
    return true;
  });

  // A layout effect to connect/disconnect the resize observer to the target.
  useLayoutEffect(() => {
    if (target) {
      const _ = ready.data; // making the linter happy
      observer.observe(target);
    }
    return () => {
      observer.disconnect();
    };
  }, [observer, target, ready.data]);

  // ElementRef is a react "ref" callback.
  // It's a function that can be passed to the "ref" prop of html elements
  // so that react can call it with the actual DOM element when rendering.
  // Components using this `useMeasure` hook will pass it like this:
  // ```
  // const [ref, bounds] = useMeasure();
  // <div ref={ref} />
  // ```
  // And this will allow us to get access to the current DOM html element
  // to set as the measurement target.
  const elementRef = useEvent((element: HTMLElement) => {
    if (target !== element) {
      setTarget(element);
    }
  });

  return [elementRef, bounds];
}
