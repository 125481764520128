import { isEqual, last } from 'lodash-es';
import React, { useEffect } from 'react';
import { useEvent } from './useEvent';

export function useHotkeyRepeat(keycode: string, callback: (event: KeyboardEvent) => void, deps: React.DependencyList) {
  // making sure it's memoized for the effects
  const cb = useEvent(callback);

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      if (isKeyCodeMatch(event, keycode)) {
        // prevent the event's default handling incase the library user
        // is trying to use a hotkey that the browser implements.
        // for example: Control+KeyP (ctrl+p)
        event.preventDefault();
        cb(event);
      }
    };

    window.addEventListener('keydown', keydown, {
      passive: true,
    });

    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, [keycode, cb, ...deps]);
}

export function useHotkey(keycode: string, callback: (event: KeyboardEvent) => void, deps: React.DependencyList) {
  // making sure it's memoized for the effects
  const cb = useEvent(callback);

  useEffect(() => {
    const state = { pressed: false };

    const keydown = (event: KeyboardEvent) => {
      if (!state.pressed && isKeyCodeMatch(event, keycode)) {
        // prevent the event's default handling incase the library user
        // is trying to use a hotkey that the browser implements.
        // for example: Control+KeyP (ctrl+p)
        event.preventDefault();
        state.pressed = true;
        cb(event);
      }
    };

    const keyup = (event: KeyboardEvent) => {
      if (state.pressed && isKeyCodeMatch(event, keycode, true)) {
        state.pressed = false;
      }
    };

    window.addEventListener('keydown', keydown, {
      passive: false,
      capture: true,
    });

    window.addEventListener('keyup', keyup, {
      passive: false,
      capture: true,
    });

    return () => {
      window.removeEventListener('keydown', keydown, {
        capture: true,
      });
      window.removeEventListener('keyup', keyup, {
        capture: true,
      });
    };
  }, [keycode, cb, ...deps]);
}

function isKeyCodeMatch(event: KeyboardEvent, keycode: string, ignoreMods?: boolean): boolean {
  if (event.code === keycode) {
    return true;
  }

  if (keycode.includes('+')) {
    const keys = keycode.split('+');
    const key = last(keys);
    const modifiers = keys.slice(0, keys.length - 1);
    return event.code === key && (ignoreMods || modMatch(event, modifiers));
  }

  return false;
}

function modMatch(event: KeyboardEvent, modifiers: string[]): boolean {
  const pressedMods = new Array<string>();
  if (event.metaKey) {
    pressedMods.push('Meta');
  }
  if (event.shiftKey) {
    pressedMods.push('Shift');
  }
  if (event.ctrlKey) {
    pressedMods.push('Control');
  }
  if (event.altKey) {
    pressedMods.push('Alt');
  }

  return isEqual(pressedMods.sort(), modifiers.sort());
}
