import React from 'react';

export interface TabProps {
  label: string;
  children?: React.ReactNode;
}

export function Tab(props: TabProps) {
  return <>{props.children}</>;
}
