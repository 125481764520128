import { lighten } from 'polished';
import { brandColors, colors } from '../Color/Colors';
import { TypeScale, baseTypeScaleSize, createResponsiveTypeScale } from '../Typography/TypeScale';
import { Theme } from './Types';

const defaultFont = 'Roboto, sans-serif';

const defaultHeadingFont = 'Montserrat, sans-serif';

const defaultTypeScale = createResponsiveTypeScale({
  xs: TypeScale.MinorSecond,
  md: TypeScale.MajorSecond,
  lg: TypeScale.MinorThird,
});

export const defaultTheme: Theme = {
  brand: {
    colors: brandColors,
  },
  typography: {
    typeScale: defaultTypeScale,
    h1: {
      fontFamily: defaultHeadingFont,
      fontSize: defaultTypeScale(5),
      fontWeight: '500',
      color: colors.blue_900,
    },
    h2: {
      fontFamily: defaultHeadingFont,
      fontSize: defaultTypeScale(4),
      fontWeight: '500',
      color: colors.blue_900,
    },
    h3: {
      fontFamily: defaultHeadingFont,
      fontSize: defaultTypeScale(3),
      fontWeight: '500',
      color: colors.blue_900,
    },
    h4: {
      fontFamily: defaultHeadingFont,
      fontSize: defaultTypeScale(2),
      fontWeight: '500',
      color: colors.blue_900,
    },
    h5: {
      fontFamily: defaultHeadingFont,
      fontSize: defaultTypeScale(1),
      fontWeight: '500',
      color: colors.blue_900,
    },
    h6: {
      fontFamily: defaultHeadingFont,
      fontSize: defaultTypeScale(0),
      fontWeight: '500',
      color: colors.blue_900,
    },
    paragraph: {
      fontFamily: defaultFont,
      fontSize: baseTypeScaleSize,
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
    text: {
      fontFamily: defaultFont,
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 1.5,
    },
    anchor: {
      fontFamily: defaultFont,
      fontSize: baseTypeScaleSize,
      fontWeight: 'normal',
    },
    ul: {
      fontFamily: defaultFont,
      fontSize: baseTypeScaleSize,
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
    ol: {
      fontFamily: defaultFont,
      fontSize: baseTypeScaleSize,
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
    li: {
      fontFamily: defaultFont,
      fontSize: baseTypeScaleSize,
      fontWeight: 'normal',
      lineHeight: 1.5,
    },
  },
  intent: {
    none: {
      fg: colors.slate_900,
      bg: colors.slate_500,
    },
    primary: {
      fg: colors.slate_50,
      bg: colors.blue_500,
    },
    secondary: {
      fg: colors.slate_50,
      bg: colors.blue_900,
    },
    success: {
      fg: colors.blue_900,
      bg: colors.emerald_300,
    },
    warning: {
      fg: colors.gray_900,
      bg: colors.yellow_700,
    },
    danger: {
      fg: colors.slate_50,
      bg: colors.red_500,
    },
  },
  body: {
    background: colors.slate_50,
    font: {
      fontFamily: defaultFont,
      fontSize: defaultTypeScale(0),
      fontWeight: 'normal',
    },
  },
  button: {
    border: {
      width: '1px',
      style: 'solid',
      radius: '8px',
    },
    font: {
      fontFamily: defaultFont,
      fontWeight: '600',
      color: colors.blue_900,
    },
    intents: {
      none: {
        fg: colors.blue_900,
        bg: colors.gray_200,
        border: {
          color: colors.gray_200,
        },
      },
      primary: {
        fg: colors.blue_900,
        bg: colors.blue_300,
        border: {
          color: colors.blue_300,
        },
      },
      secondary: {
        fg: colors.slate_50,
        bg: colors.blue_900,
        border: {
          color: colors.blue_900,
        },
      },
      success: {
        fg: colors.blue_900,
        bg: colors.emerald_300,
        border: {
          color: colors.emerald_300,
        },
      },
      warning: {
        fg: colors.slate_900,
        bg: colors.yellow_500,
        border: {
          color: colors.yellow_500,
        },
      },
      danger: {
        fg: colors.slate_100,
        bg: colors.red_500,
        border: {
          color: colors.red_500,
        },
      },
    },
  },
  input: {
    font: {
      fontFamily: defaultFont,
      fontSize: defaultTypeScale(0),
      fontWeight: 'normal',
    },
    border: {
      width: '1px',
      style: 'solid',
      radius: '4px',
      color: colors.gray_400,
    },
    placeholder: {
      font: {
        fontFamily: defaultFont,
        fontSize: defaultTypeScale(0),
        fontWeight: 'normal',
      },
    },
  },
  formItem: {
    label: {
      font: {
        fontFamily: defaultFont,
        fontSize: defaultTypeScale(0),
        fontWeight: 'normal',
        color: 'black',
      },
    },
    errorText: {
      font: {
        fontFamily: defaultFont,
        fontSize: defaultTypeScale(-1),
      },
    },
  },
  table: {
    th: {
      font: {
        fontFamily: defaultFont,
        fontSize: defaultTypeScale(0),
        fontWeight: 'bold',
      },
      padding: '1rem',
    },
    td: {
      font: {
        fontFamily: defaultFont,
        fontSize: defaultTypeScale(0),
        fontWeight: 'normal',
      },
      padding: '1rem',
    },
  },
  appbar: {
    bg: colors.gray_200,
    fg: '#1c1c1c',
  },
  drawer: {
    bg: colors.blue_900,
    fg: 'white',
    header: {
      bg: colors.blue_900,
      fg: 'white',
    },
    group: {
      bg: colors.blue_900,
      fg: colors.blue_500,
    },
    item: {
      bg: colors.blue_900,
      fg: 'white',
    },
    panel: {
      bg: lighten(0.1, colors.blue_900),
      fg: 'white',
    },
    paddings: {
      vertical: '8px',
      horizontal: '24px',
    },
  },
  callout: {
    font: {
      fontFamily: defaultFont,
      fontSize: defaultTypeScale(0),
    },
    padding: '16px',
    border: {
      radius: '8px',
      style: 'solid',
      width: '1px',
      color: colors.slate_100,
    },
    intents: {
      none: {
        fg: colors.slate_900,
        bg: 'white',
        circleBg: colors.slate_100,
        border: {
          color: colors.gray_200,
        },
      },
      primary: {
        fg: colors.slate_900,
        bg: colors.blue_100,
        circleBg: 'white',
        border: {
          color: colors.blue_300,
        },
      },
      secondary: {
        fg: colors.slate_50,
        bg: colors.blue_900,
        circleBg: 'rgba(255, 255, 255, 0.1)',
        border: {
          color: colors.blue_900,
        },
      },
      success: {
        fg: colors.slate_900,
        bg: colors.emerald_100,
        circleBg: 'rgba(255, 255, 255, 0.5)',
        border: {
          color: colors.emerald_300,
        },
      },
      warning: {
        fg: colors.slate_900,
        bg: colors.yellow_100,
        circleBg: 'rgba(255, 255, 255, 0.5)',
        border: {
          color: colors.yellow_500,
        },
      },
      danger: {
        fg: colors.slate_900,
        bg: colors.red_100,
        circleBg: 'rgba(255, 255, 255, 0.5)',
        border: {
          color: colors.red_300,
        },
      },
    },
  },
  dialog: {
    gap: 1,
  },
  paper: {
    border: {
      style: 'solid',
      radius: '12px',
      width: '0px',
    },
  },
  alertBar: {
    padding: '8px 16px',
    border: {
      radius: '4px',
      style: 'solid',
      width: '1px',
    },
    intents: {
      none: {
        fg: 'black',
        bg: 'white',
        border: {
          color: colors.gray_300,
        },
      },
      primary: {
        fg: colors.slate_900,
        bg: colors.blue_100,
        border: {
          color: colors.blue_300,
        },
      },
      secondary: {
        fg: colors.slate_50,
        bg: colors.blue_900,
        border: {
          color: colors.blue_900,
        },
      },
      success: {
        fg: 'white',
        bg: colors.emerald_300,
        border: {
          color: colors.emerald_300,
        },
      },
      warning: {
        fg: colors.slate_900,
        bg: colors.yellow_50,
        border: {
          color: colors.yellow_500,
        },
      },
      danger: {
        fg: colors.slate_100,
        bg: colors.red_500,
        border: {
          color: colors.red_500,
        },
      },
    },
  },
};
