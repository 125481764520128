import { deburr, toLower } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Column } from '../DataTableContracts';

interface FilterHook<T> {
  rows: T[];
  filter: string;
  setFilter: (search: string) => void;
}

export function useFilter<T>(rows: T[], columns: Column<T>[]): FilterHook<T> {
  const [filter, setFilter] = useState<string>('');

  const filteredRows = useMemo(() => {
    if (filter === '') {
      return rows;
    }

    // filter the rows by the column data selector's result.
    return rows.filter((row) => {
      for (const column of columns) {
        // if there's no column data selector then we cannot support
        // filtering for this columns (skip it).
        if (!column.selector) {
          return false;
        }

        // otherwise we can call the selector to get a value to filter on.
        const cellvalue = column.selector(row);
        if (deburr(toLower(JSON.stringify(cellvalue))).includes(filter.toLowerCase())) {
          return true;
        }
      }

      return false;
    });
  }, [rows, filter, columns]);

  return useMemo(
    () => ({
      rows: filteredRows,
      filter: filter,
      setFilter: setFilter,
    }),
    [filter, filteredRows]
  );
}
