import { clamp, isEqual } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { useEvent } from '../../Hooks/useEvent';
import { useHotkey, useHotkeyRepeat } from '../../Hooks/useHotkey';
import { Paper } from '../../Paper/Paper';
import { Spacer } from '../../Spacer/Spacer';
import { StyledTextInput, TextInput } from '../TextInput/TextInput';
import { Option, SelectOption, StyledOption } from './SelectOption';

interface SelectListProps<T> {
  /**
   * @deprecated
   */
  name?: string;
  /**
   * @deprecated
   */
  label?: string;

  options: SelectOption<T>[];
  value?: T;
  onChange: (next: T, option: SelectOption<T>) => void;
  width?: number;
  emptyOptionsPlaceholder?: string;
  disableSearch?: boolean;
}

export function SelectList<T>(props: SelectListProps<T>) {
  const [search, setSearch] = useState('');

  const options = useMemo(() => {
    let options = props.options;

    options = options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));

    return options;
  }, [props.options, search]);

  const selectedOptionIndex = useMemo(() => {
    return options.findIndex((option) => {
      return isEqual(option.value, props.value);
    });
  }, [options, props.value]);

  const [hoverIndex, setHoverIndex] = useState(selectedOptionIndex);

  useHotkeyRepeat(
    'ArrowDown',
    useEvent(() => {
      setHoverIndex((idx) => {
        if (idx > options.length) {
          return 0;
        }
        return clamp(idx + 1, 0, options.length - 1);
      });
    }),
    []
  );

  useHotkeyRepeat(
    'ArrowUp',
    useEvent(() => {
      setHoverIndex((idx) => {
        if (idx > options.length) {
          return 0;
        }
        return clamp(idx - 1, 0, options.length - 1);
      });
    }),
    []
  );

  useEffect(() => {
    if (hoverIndex < 0 || hoverIndex > options.length - 1) {
      setHoverIndex(0);
    }
  }, [hoverIndex, options.length]);

  useHotkey(
    'Enter',
    useEvent((event) => {
      const option = options[hoverIndex];
      if (option) {
        event.preventDefault();
        event.stopPropagation();
        props.onChange(option.value, option);
      }
    }),
    []
  );

  useHotkey(
    'Tab',
    useEvent(() => {
      props.onChange(props.value!, options[selectedOptionIndex]);
    }),
    [selectedOptionIndex]
  );

  return (
    <Paper elevation={2} nopad>
      <StyledContainer justify="stretch" width={props.width}>
        {props.options.length > 8 && !props.disableSearch && (
          <TextInput autoFocus name="search" value={search} onChange={setSearch} />
        )}
        <StyledOptionList justify="stretch">
          {options.map((option, index) => (
            <Option
              key={option.key ?? index}
              option={option}
              isSelected={index === selectedOptionIndex}
              isHovered={index === hoverIndex}
              onClick={() => props.onChange(option.value, option)}
              onHover={() => setHoverIndex(index)}
            />
          ))}

          {options.length === 0 && (
            <StyledOption isHovered={false} isSelected={false}>
              {props.emptyOptionsPlaceholder ?? 'No options'}
            </StyledOption>
          )}
        </StyledOptionList>
      </StyledContainer>
    </Paper>
  );
}

const StyledContainer = styled(Spacer)<{ width?: number }>`
  min-width: ${(props) => props.width ?? 240}px;

  ${StyledTextInput} {
    margin: 2px;
    border-radius: 0 !important;
  }
`;

const StyledOptionList = styled(Spacer)`
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
`;
