import { ResponsiveBreakpoints, resolveResponsiveBreakpoints } from '../Responsive/Breakpoints';

/**
 * BaseTypeScaleSize is always 16px.
 *
 * The base size will always be the same as `typeScale(<any-scale>, 0)`
 * i.e. size=0 is just base typescale size.
 *
 * The choice to use "px" as a unit is important.
 *
 * Most typescales discussed online will use either "rem" or "em" units
 * where the expected root element font-size is 16px i.e. 1rem == 16px.
 * The EventsPass website uses 13px for the root font-size which is too
 * small for the new uikit to adopt.
 *
 * By making the typescale work in "px" we avoid having to deal with the
 * small rem size.
 */
export const baseTypeScaleSize = '16px';

export enum TypeScale {
  MinorSecond = 1.067,
  MajorSecond = 1.125,
  MinorThird = 1.2,
  MajorThird = 1.25,
  PerfectFourth = 1.333,
  AugmentedFourth = 1.414,
  PerfectFifth = 1.5,
  GoldenRatio = 1.618,
}

export const TypeScales = [
  TypeScale.MinorSecond,
  TypeScale.MajorSecond,
  TypeScale.MinorThird,
  TypeScale.MajorThird,
  TypeScale.PerfectFourth,
  TypeScale.AugmentedFourth,
  TypeScale.PerfectFifth,
  TypeScale.GoldenRatio,
];

export type TypeScaleSize = -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5;

export function typeScale(scale: TypeScale, size: TypeScaleSize, cssValue: string = baseTypeScaleSize) {
  // Split the value/unit from the base type scale size.
  // We need the base for our scale calculation which is unit-independent
  // but we still need the unit type for our resulting font-size to use.
  // This has the nice effect of allowing the base type scale constant
  // to control the unit space that our typescale uses.
  const [base, unit] = splitUnit(cssValue);

  // Typescale calculation is based off this website:
  // https://typescale.com/
  const scaled = base * scale ** size;

  // Round to 3 decimal places.
  const rounded = Math.round((scaled + Number.EPSILON) * 1000) / 1000;

  // Return the calculated size with the "px" unit.
  return `${rounded}${unit}`;
}

export type TypeScaleFn = (size: TypeScaleSize, cssValue?: string) => string;

/**
 * createTypeScale returns a new function that can be called with
 * a type scale size to produce it's corresponding font-size based
 * on the type scale provided.
 */
export function createTypeScale(scale: TypeScale): TypeScaleFn {
  return (size: TypeScaleSize, cssValue?: string) => typeScale(scale, size, cssValue);
}

export type ResponsiveTypeScaleFn = (size: TypeScaleSize, cssValue?: string) => ResponsiveBreakpoints<string>;

/**
 * createResponsiveTypeScale takes a breakpoint configuration of TypeScales
 * and returns a new function that can be called with a type scale size
 * to produce it's corresponding font-size breakpoints.
 */
export function createResponsiveTypeScale(scale: ResponsiveBreakpoints<TypeScale>): ResponsiveTypeScaleFn {
  const values = resolveResponsiveBreakpoints(scale);
  return (size, cssValue) => ({
    xs: typeScale(values.xs, size, cssValue),
    sm: typeScale(values.sm, size, cssValue),
    md: typeScale(values.md, size, cssValue),
    lg: typeScale(values.lg, size, cssValue),
    xl: typeScale(values.xl, size, cssValue),
    xxl: typeScale(values.xxl, size, cssValue),
  });
}

// SplitUnit takes a css value like "16px" and returns [16, "px"]
// i.e. split the value and unit from a css value.
function splitUnit(cssvalue: string): [number, string] {
  const value = parseFloat(cssvalue);
  const unit = cssvalue.replace(String(value), '').trim();
  return [value, unit];
}
