import React, { useEffect, useRef, useState } from 'react';
import { useEvent } from '../../Hooks/useEvent';
import { FormInputProps, formInput } from '../FormInput';
import { StyledTextInput } from '../TextInput/TextInput';

export interface NumberInputProps extends FormInputProps<number> {
  placeholder?: string;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  min?: number;
  max?: number;
  step?: number;
  /**
   * Decimals can be used to render the number with a fixed
   * number of decimal places.
   *
   * Implemented using `value.toFixed(decimals)`.
   */
  decimals?: number;
}

export const NumberInput = formInput((props: NumberInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);

  const onChange = useEvent((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.currentTarget.valueAsNumber, event);
    }
  });

  useEffect(() => {
    if (props.autoFocus && ref.current) {
      ref.current.focus();
      if (ref.current.value) {
        ref.current.select();
      }
    }
  }, [props.autoFocus]);

  return (
    <StyledTextInput
      {...props}
      ref={ref}
      value={formatDisplay(props.value, props.decimals, focused)}
      onChange={onChange}
      placeholder={props.placeholder ?? `${props.label?.toLocaleLowerCase?.()}...`}
      type="number"
      min={props.min}
      max={props.max}
      step={props.step}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
});

function formatDisplay(value: number | undefined, decimals: number | undefined, focused: boolean): string {
  if (value === undefined) {
    return '';
  }

  // if the input is focused we avoid formatting because it disrupts
  // the user while they are typing.
  if (focused) {
    return String(value);
  }

  if (decimals !== undefined) {
    return value.toFixed(decimals);
  }

  return String(value);
}
