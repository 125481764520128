import { motion } from 'framer-motion';
import { isEqual } from 'lodash-es';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useAsync } from '../../Hooks/useAsync';
import { useAsyncEvent } from '../../Hooks/useAsyncEvent';
import { useDebouncedEvent } from '../../Hooks/useDebouncedEvent';
import { useEvent } from '../../Hooks/useEvent';
import { Popover } from '../../Popover/Popover';
import { isNullOrWhitespace } from '../../Text';
import { FormInputProps, formInput } from '../FormInput';
import { SelectList } from '../Select/SelectList';
import { SelectOption } from '../Select/SelectOption';
import { StyledTextInput } from '../TextInput/TextInput';

// The form input can emit T or undefined because the user may clear the input field.
export interface TypeaheadSelectProps<T> extends FormInputProps<T | undefined> {
  options: SelectOption<T>[] | T[];
  placeholder?: string;
  width?: number;
  autoWidth?: boolean;
  onSearch: (query: string) => Promise<void>;
}

/**
 * TypeaheadSelect implements a select input that the user can type in to search
 * a large dataset, possibly powered by an API.
 *
 * The parent component should respond to the `onSearch` event by fetching any
 * matching options from the desired data source and passing them back into
 * the typeahead component as options.
 *
 * NOTE: the current implementation has been copy/pasted from the Select input
 * implementation and requires refactoring to split out shared code to improve
 * maintainability.
 *
 * Select and Typeahead select are complex components with lots of subtle behaviours
 * so this refactor is going to be hard to get right :(
 *
 * Until the refactor can improve the implementation consider the Select input
 * as the main implementation and the typeahead select element to be the hacky
 * copy/pasted version (i.e. prioritize the select input for code quality).
 */
export const TypeaheadSelect = formInput(<T,>(props: TypeaheadSelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string>();
  const [autoWidth, setAutoWidth] = useState(0);
  const spanRef = useRef<HTMLSpanElement>(null);

  // normalize the props.options value to a SelectOption<T>
  // i.e. if a string[] is passed then convert it.
  // we support this so that it's simple to use the <Select /> element
  // with a hardcoded list of string/number options.
  const options = useMemo(() => {
    return props.options.map((option): SelectOption<T> => {
      if (option && typeof option === 'object' && 'label' in option && 'value' in option) {
        return option;
      }
      return { key: String(option), label: String(option), value: option };
    });
  }, [props.options]);

  // SelectedValue is the currented selected value to display.
  // We find this value by looking up the "value" prop in the given
  // list of "options".
  // We search by reference equality first i.e. the === operator because
  // it's fast and works well for all value types (numbers, strings, object references etc.)
  // If a reference equality match isn't found then we must search by structural equality.
  // The structural equality is slower but is important because the library user may expect
  // objects that look the same to be the same
  // i.e. {"name": "bob"} !== {"name": "bob"} because they are different object references
  // but the user probably expected them to be equal for the purpose of the <Select/> element.
  // So structural equality using lodash's "isEqual" is important.
  // biome-ignore lint/correctness/useExhaustiveDependencies: linter is cooked on options[index]
  const selectedValue = useMemo(() => {
    // search for the selected value using reference equality first
    const found = options.findIndex((option) => option.value === props.value);
    if (found !== -1) {
      return options[found];
    }
    // if the value wasn't found with reference equality then try structural
    return options.find((option) => isEqual(option.value, props.value));
  }, [props.value, options]);

  const inputLabel = query || selectedValue?.label || props.placeholder || 'Search...';

  const onSearch = useAsyncEvent(props.onSearch);

  const onSearchDebounced = useDebouncedEvent(onSearch.callback, 150);

  // The "autoWidth" feature will size the input based on the selected
  // option's label size which depends on the rendered font so we must wait
  // for the font to be ready within the document so that we can correctly
  // measure the hidden `spanRef`.
  // This hook just waits for the document's fonts to be ready.
  const ready = useAsync(async () => {
    await document.fonts.ready;
    return true;
  });

  // When the selected option's label changes or the document's fonts become
  // ready we will measure the `spanRef` element and remember it's size
  // so that it can be used for the select element in the case that
  // autoWidth has been enabled.
  // biome-ignore lint/correctness/useExhaustiveDependencies: running effect when the deps change even though we don't reference them directly (indirectly measuring them via the DOM).
  useLayoutEffect(() => {
    setAutoWidth(spanRef.current?.offsetWidth ?? 0);
  }, [inputLabel, ready.data]);

  const onChange = useEvent((next: T, _option: SelectOption<T>) => {
    setOpen(false);
    setQuery(undefined);
    props.onChange?.(next, undefined);
  });

  // calculating the select box width is complicated because we want to achieve a few things:
  // 1. if the autoWidth feature is enabled then we need to use
  //    the measured content width of the text box but allow the props.width
  //    setting to act as a minimum value; or a hardcoded minimum of 50
  //    the minimum is important incase the text box is empty.
  // 2. otherwise we want to use the props.width setting as the width
  // 3. otherwise we fallback to a default width of 240 when no specific width or autoWidth is provided.
  const width = props.autoWidth ? Math.max(autoWidth, props.width ?? 50) : props.width ?? 240;

  const popover = (
    <SelectList
      options={options}
      value={props.value}
      onChange={onChange}
      width={width}
      emptyOptionsPlaceholder={onSearch.isFetching || onSearchDebounced.isWaiting ? 'Searching...' : 'No options'}
      disableSearch
    />
  );

  if (!ready.data) {
    return null;
  }

  return (
    <>
      <StyledHiddenSpan ref={spanRef}>{inputLabel}</StyledHiddenSpan>
      <Popover
        open={open && query !== undefined && query.length > 0}
        popover={popover}
        onOpen={() => {
          // noop because we want to control the popover
          // based on the text input focus
        }}
        onDismiss={() => setOpen(false)}
        placement="bottom-start"
      >
        {autoWidth > 0 && (
          <StyledSelectTextInput
            name={props.name}
            value={(open ? query ?? selectedValue?.label : selectedValue?.label) ?? ''}
            placeholder={props.placeholder || 'Search...'}
            onChange={(event: any) => {
              setQuery(event.target.value);
              if (!isNullOrWhitespace(event.target.value)) {
                onSearchDebounced.callback(event.target.value);
              } else {
                // if the query is empty we "onChange(undefined)" so that
                // the parent component knows the user cleared the input.
                props.onChange?.(undefined, event);
              }
            }}
            onFocus={(event: any) => {
              setOpen(true);
              event.target.select();
            }}
            initial={{
              width: width,
            }}
            animate={{
              width: width,
            }}
            disabled={props.disabled}
          />
        )}
      </Popover>
    </>
  );
});

const StyledSelectTextInput = motion(styled(StyledTextInput)`
  cursor: text;
`);

/**
 * This element is used to measure the select element's label size.
 * The idea is to render an offscreen element so that the browser can
 * tell us how big the content is (element.offsetWidth) and then use
 * that to update the actual select box element size.
 * This approach is used because you cannot measure the size the text
 * in an input element directly.
 */
const StyledHiddenSpan = styled.span`
  padding: 0.5rem;
  padding-right: 2rem;
  border: 2px solid red;
  position: absolute;
  top: 0;
  left: -999px;
  opacity: 0;
  z-index: -999;
  pointer-events: none;
`;
