import { css } from 'styled-components';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { Border, Typography } from './Types';

export function typography(config: Typography) {
  return css`
    font-family: ${config.fontFamily};
    font-weight: ${config.fontWeight};
    color: ${config.color ?? 'currentColor'};

    ${responsiveFontSize(config.fontSize)}

    // The default value (1.15) is the line-height set by Normalize.ts
    // but we explicitly set it here because legacy
    // css in eventspass web otherwise breaks the style.
    // !important is problematic but is used here because
    // the legacy styles also use it.
    line-height: ${config.lineHeight ?? 1.15} !important;
  `;
}

export function responsiveFontSize(size?: Breakpoints<string>) {
  return breakpointsMixin(
    size,
    (value) => css`
      font-size: ${value};
    `
  );
}

export function border(config: Border, color?: string) {
  return css`
    border-style: ${config.style};
    border-width: ${config.width};
    border-radius: ${config.radius};
    border-color: ${color ?? config.color};
  `;
}
