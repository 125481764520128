/**
 * Ok, ok, yes, this looks like complete madness but hang in there.
 *
 * The deepAssign function has been written because the "merge"
 * function from lodash doesn't consider "undefined" values
 * when merging, meaning you cannot unset a property during the
 * merge.
 *
 * Object.assign (stdlib), assign (lodash) both do what we expect,
 * they take a list of objects where keys in the latter overwrite
 * keys in the former; except they aren't recursive. They only operate
 * on the first level of keys in the object ignoring nested objects.
 *
 * So then we arrive at deepAssign which is essentially assign() from
 * lodash but it works recursively :)
 *
 * If you had to read this comment because you're debugging then I
 * appologize :(
 */

import { assignWith, clone, isFunction, isObject } from 'lodash-es';

export function deepAssign<T, T0>(obj: T, src0: T0): T & T0;
export function deepAssign<T, T0, T1>(obj: T, src0: T0, src1: T1): T & T0 & T1;
export function deepAssign<T, T0, T1, T2>(obj: T, src0: T0, src1: T1, src2: T2): T & T0 & T1 & T2;
export function deepAssign<T, T0, T1, T2, T3>(obj: T, src0: T0, src1: T1, src2: T2, src3: T3): T & T0 & T1 & T2 & T3;
export function deepAssign(obj: any, ...sources: any[]): any {
  return assignWith(obj, ...sources, customizer);
}

function customizer(objValue: any, srcValue: any) {
  if (isObject(objValue) && isObject(srcValue) && !isFunction(objValue)) {
    return deepAssign(clone(objValue), srcValue);
  }

  return srcValue;
}
