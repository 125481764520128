import React from 'react';
import { colors } from '../Color/Colors';
import { SVGIcon } from './Icons';

export interface IconProps {
  icon: SVGIcon;
  padding?: number;
  size?: number;
  color?: string;
  muted?: boolean;
}

export const Icon = React.forwardRef((props: IconProps, ref: React.ForwardedRef<unknown>) => {
  const size = sizePx(props.size ?? 1);

  let color = props.color ?? 'currentColor';

  if (props.muted) {
    color = colors.gray_500;
  }

  return <props.icon ref={ref} color={color} width={size} height={size} style={{ padding: props.padding }} />;
});

function sizePx(value: number | undefined) {
  return (value ?? 1) * 16;
}
