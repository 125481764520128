import React from 'react';
import { useMediaQuery } from '../Hooks/useMediaQuery';
import { ScreenSize, screens } from '../Responsive/Breakpoints';

export interface MediaProps {
  size: ScreenSize;
  down?: boolean;
  children?: React.ReactNode;
}

export function MatchMedia(props: MediaProps) {
  const matches = useMediaQuery(`(${props.down ? 'max' : 'min'}-width: ${screens[props.size]}px)`);

  if (matches) {
    return <>{props.children}</>;
  }

  return null;
}
