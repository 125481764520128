import { AnimatePresence, MotionConfig, Transition, motion } from 'framer-motion';
import React, { memo, useCallback, useRef, useState } from 'react';

interface Props {
  disabled?: boolean;
}

function RippleImpl(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState(0);
  const nextId = useIdSequence();

  const start = useCallback((event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent) => {
    if (!ref.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    let clientX = -1;
    let clientY = -1;

    if (isMouseEvent(event) && event.button === 0) {
      clientX = event.clientX;
      clientY = event.clientY;
    }

    if (isKeyboardEvent(event) && event.code === 'Space') {
      clientX = (rect.right - rect.left) / 2;
      clientY = (rect.bottom - rect.top) / 2;
    }

    if (isTouchEvent(event)) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    }

    const originX = clientX - rect.left;
    const originY = clientY - rect.top;

    setPosition({ x: originX, y: originY });
    setSize(getRippleRadius({ x: clientX, y: clientY }, rect) * 2);
    setVisible(true);
  }, []);

  const stop = () => {
    setVisible(false);
  };

  return (
    <MotionConfig transition={transition}>
      <div
        ref={ref}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          overflow: 'hidden',
        }}
        onMouseDown={start}
        onMouseOut={stop}
        onMouseUp={stop}
        onTouchStart={start}
        onTouchEnd={stop}
        onKeyDownCapture={start}
        onKeyUpCapture={stop}
      >
        <AnimatePresence>
          {visible && !props.disabled && (
            <motion.div
              key={nextId()}
              initial={{
                opacity: 0.1,
                transform: 'scale(0)',
              }}
              animate={{
                opacity: 0.3,
                transform: 'scale(1)',
              }}
              exit={{
                opacity: 0,
                transform: 'scale(1)',
              }}
              style={{
                transformOrigin: '50% 50%',
                position: 'absolute',
                backgroundColor: 'black',
                borderRadius: '50%',
                pointerEvents: 'none',
                userSelect: 'none',
                top: `calc(${y}px - ${size / 2}px)`,
                left: `calc(${x}px - ${size / 2}px)`,
                width: size,
                height: size,
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </MotionConfig>
  );
}

export const Ripple = memo(RippleImpl) as typeof RippleImpl;

interface Coordinate {
  x: number;
  y: number;
}

function getCoordinateDistance(a: Coordinate, b: Coordinate) {
  return Math.sqrt((b.x - a.x) ** 2 + (b.y - a.y) ** 2);
}

function getRippleRadius(origin: Coordinate, rect: DOMRect) {
  const topLeft = {
    x: rect.left,
    y: rect.top,
  };
  const topRight = {
    x: rect.left + rect.width,
    y: rect.top,
  };
  const bottomRight = {
    x: rect.left + rect.width,
    y: rect.top + rect.height,
  };
  const bottomLeft = {
    x: rect.left,
    y: rect.top + rect.height,
  };

  return Math.max(
    getCoordinateDistance(origin, topLeft),
    getCoordinateDistance(origin, topRight),
    getCoordinateDistance(origin, bottomRight),
    getCoordinateDistance(origin, bottomLeft)
  );
}

const transition: Transition = {
  type: 'spring',
  duration: 0.6,
};

function isKeyboardEvent(
  event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent
): event is React.KeyboardEvent {
  return event.type === 'keydown';
}

function isMouseEvent(event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent): event is React.MouseEvent {
  return event.type === 'mousedown' && !isTouchEvent(event);
}

function isTouchEvent(event: React.MouseEvent | React.TouchEvent | React.KeyboardEvent): event is React.TouchEvent {
  return 'touches' in event && event.touches.length > 0;
}

function useIdSequence() {
  const ref = useRef(0);
  return () => {
    ref.current += 1;
    return ref.current;
  };
}
