import { css, styled } from 'styled-components';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { border } from '../Theme/Mixins';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface PaperProps {
  elevation?: Breakpoints<0 | 1 | 2 | 3 | 4 | 5>;
  pop?: boolean;
  nopad?: boolean;
}

export const Paper = styled.div.withConfig({ shouldForwardProp: customShouldForwardProp })<PaperProps>`
  position: relative;
  background: white;
  padding: ${(props) => (props.nopad ? '0' : '1rem')};
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${(props) => border(props.theme.paper.border)}

  ${(props) =>
    breakpointsMixin(
      props.elevation,
      (elevation) => css`
        box-shadow: ${shadows[elevation ?? 0]};
      `
    )}

  ${(props) =>
    props.pop &&
    breakpointsMixin(
      props.elevation,
      (elevation) => css`
        &:hover {
          box-shadow: ${shadowsHover[elevation ?? 0]};
        }
      `
    )}
`;

export const PaperPadding = styled.div`
  padding: 1rem;
`;

const shadows = {
  0: '0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0)',
  1: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
  2: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  3: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
  4: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
  5: '0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)',
} as const;

const shadowsHover = {
  0: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  1: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
  2: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
  3: '0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)',
  4: '0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)',
  5: '0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)',
} as const;

function customShouldForwardProp(prop: string): boolean {
  if (prop === 'elevation') {
    return false;
  }
  return shouldForwardProp(prop);
}
