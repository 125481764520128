import { orderBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useEvent } from '../../Hooks/useEvent';
import { Column, columnKey } from '../DataTableContracts';

interface Sorting {
  key: string;
  ascending: boolean;
}

export interface SortingHook<T> {
  rows: T[];
  sorting: Sorting | undefined;
  toggle: (column: Column<T>) => void;
  isColumnSorted: (column: Column<T>) => boolean;
}

export function useSorting<T>(rows: T[], columns: Column<T>[]): SortingHook<T> {
  const [sorting, setSorting] = useState<Sorting | undefined>();

  // sorting changes like this:
  // - click: sort ascending
  // - click again: sort descending
  // - click a third time: remove sorting (back to default state)
  const toggle = useEvent((column: Column<T>) => {
    setSorting((current) => {
      const key = columnKey(column);
      if (current === undefined || key !== current.key) {
        return {
          key: key,
          ascending: true,
        };
      }
      if (current.ascending) {
        return { key: key, ascending: false };
      }
      return undefined;
    });
  });

  const sortedRows = useMemo(() => {
    if (sorting) {
      return orderBy(
        rows,
        (row) => {
          const column = columns.find((column) => columnKey(column) === sorting.key);
          if (column?.selector) {
            return column.selector(row);
          }
        },
        sorting.ascending ? 'asc' : 'desc'
      );
    }
    return rows;
  }, [rows, columns, sorting]);

  return useMemo(() => {
    return {
      rows: sortedRows,
      sorting: sorting,
      toggle: toggle,
      isColumnSorted: (column: Column<T>) => {
        return sorting?.key === columnKey(column);
      },
    };
  }, [sorting, toggle, sortedRows]);
}
