// https://usehooks.com/useLocalStorage/

import { useState } from 'react';

/**
 * useLocalStorage works like `useState` except the value will persist
 * in the browser's local storage.
 * @param key the localstorage key
 * @param initialValue
 * @returns
 */
export function useLocalStorage<T>(key: string, initialValue: T) {
  const [value, setValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      if (!item) {
        return initialValue;
      }
      return JSON.parse(item).value;
    } catch (error) {
      // todo this needs to be logged by a real logging system
      // biome-ignore lint/suspicious/noConsoleLog: <explanation>
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setter = (nextValue: T | ((currentValue: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = nextValue instanceof Function ? nextValue(value) : nextValue;
      // Save state
      setValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify({ value: valueToStore }));
      }
    } catch (error) {
      // TODO: this needs to be logged by a real logging system
      // biome-ignore lint/suspicious/noConsoleLog: <explanation>
      console.log(error);
    }
  };

  return [value, setter] as const;
}
