import React, { Children } from 'react';

export function createSlot<T extends React.FC<any>>(name: string, Component: T): T {
  const wrapper = (props: any) => {
    return <Component {...props} />;
  };
  wrapper.__slot = name;
  return wrapper as unknown as T;
}

export function slots<T extends string[]>(
  children: React.ReactNode,
  ...names: T
): Record<T[number] | 'children', React.ReactNode> {
  const result: any = {
    children: [],
  };

  for (const __slot of names) {
    result[__slot] = [];
  }

  Children.forEach(children, (child: any) => {
    const __slot = child?.type?.__slot;
    if (!__slot) {
      result['children'].push(child);
    } else if (names.includes(__slot)) {
      result[__slot].push(child);
    }
  });

  return result;
}
