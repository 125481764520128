import React, { useState } from 'react';
import { useAsync } from '../../Hooks/useAsync';
import { useAsyncEvent } from '../../Hooks/useAsyncEvent';
import { useEqualityMemo } from '../../Hooks/useEqualityMemo';
import { useEvent } from '../../Hooks/useEvent';
import { useMap } from '../../Hooks/useMap';
import { PaginationHook, defaultPageSize } from './PaginationHook';

export interface KeysetPaginatedResponse<TItem> {
  items: TItem[];
  nextPageToken: string;
}

export type KeysetPaginationFetcher<TItem> = (
  pageToken: string | undefined,
  pageSize: number
) => Promise<KeysetPaginatedResponse<TItem>>;

export function useKeysetPagination<TItem>(
  fetcher: KeysetPaginationFetcher<TItem>,
  deps: React.DependencyList
): PaginationHook<TItem> {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const pageCache = useMap<number, PageCacheEntry<TItem>>();

  const loadPage = useEvent(async (pageToken: string | undefined, pageNumber: number) => {
    const response = await fetcher(pageToken, pageSize);
    pageCache.set(pageNumber, {
      response: response,
      requestedPageSize: pageSize,
      requestedPageToken: pageToken,
    });
  });

  const reset = useAsyncEvent(async () => {
    setCurrentPage(0);
    await loadPage(undefined, 0);
  });

  const initial = useAsync(async () => {
    await reset.callback();
  }, deps);

  useAsync(async () => {
    // only refresh the page if it's in the page cache.
    // the reason for this check is a little bit hacky, but
    // on the first render we don't want to "refresh" and "reset"
    // which happens because of the initial useAsync call.
    // basically we want to refresh when the page size changes
    // but not on the first render.
    if (pageCache.has(currentPage)) {
      await refresh.callback();
    }
  }, [pageSize]);

  const next = useAsyncEvent(async () => {
    const page = pageCache.get(currentPage);
    if (page) {
      if (currentPage > 0 && page.response.items.length === 0) {
        // we've already hit the last page when the fetcher
        // has returned nothing for the current page that's
        // also not the first page.
        // it's possible that the first page also has nothing
        // but we can at least keep fetching page 0 because
        // it's the first page and a new result may show up.
        return;
      }

      // check if the next page is already cached.
      // if it's not cached, or it's cached but the page token is stale
      // then we need to reload the page.
      // the page token can become stale if the user has changed
      // API request options related to ordering, filtering, page size, etc.
      const nextPage = pageCache.get(currentPage + 1);
      if (!nextPage || nextPage.requestedPageToken !== page.response.nextPageToken) {
        await loadPage(page.response.nextPageToken, currentPage + 1);
      }

      // increment the current page number.
      setCurrentPage(currentPage + 1);
    }
  });

  const previous = useAsyncEvent(async () => {
    if (currentPage > 0) {
      const previousPage = pageCache.get(currentPage - 1);
      if (previousPage?.requestedPageSize !== pageSize) {
        await loadPage(previousPage?.requestedPageToken, currentPage - 1);
      }

      // it's safe to simply decrement the current page number here
      // because all previous pages will be in the page cache given
      // the only possible way to arrive at a particular page with
      // keyset pagination is to first visit all previous pages.
      setCurrentPage(currentPage - 1);
    }
  });

  const refresh = useAsyncEvent(async () => {
    const page = pageCache.get(currentPage);
    await loadPage(page?.requestedPageToken, currentPage);
  });

  return useEqualityMemo({
    items: pageCache.get(currentPage)?.response.items ?? emptyArray,
    currentPage: currentPage,
    pageSize: pageSize,
    setPageSize: setPageSize,
    next: next.callback,
    previous: previous.callback,
    refresh: refresh.callback,
    reset: reset.callback,
    isLoading: initial.isFetching || next.isFetching || previous.isFetching || refresh.isFetching,
    error: initial.error ?? refresh.error ?? previous.error ?? refresh.error,
  });
}

// This is an immutable empty array that we use as the default
// list of items returned by the keyset pagination hook.
// This exists so that we don't initialize a new empty list
// on every render in the case that the keyset pagination
// returns an empty result set.
// The idea is to help reduce reference changes between
// re-renders so that other hook dependencies change less.
// It's immutable because the code calling the keyset pagination
// hook should not be modifying the result array and especially
// not this global default one which should remain empty.
const emptyArray: any[] = Object.freeze([]) as any;

interface PageCacheEntry<TItem> {
  response: KeysetPaginatedResponse<TItem>;
  requestedPageSize: number;
  requestedPageToken: string | undefined;
}
