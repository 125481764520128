import { LayoutGroup, MotionConfig, Transition, motion } from 'framer-motion';
import { drop } from 'lodash-es';
import React, { Fragment, memo, useMemo } from 'react';
import { styled } from 'styled-components';
import { Button } from '../Buttons/Button';
import { IconButton } from '../Buttons/IconButton';
import { Checkbox } from '../Forms/Checkbox/Checkbox';
import { TextInput } from '../Forms/TextInput/TextInput';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  ArrowsUpDownIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '../Icon/Icons';
import { Spinner } from '../Loading/Spinner';
import { Paper } from '../Paper/Paper';
import { Spacer } from '../Spacer/Spacer';
import { TBody, TFoot, THead, TRow, Table, Td, Th } from '../Table/Table';
import { Column, DataSource, ExpandedRowRenderProps, RowID, columnKey } from './DataTableContracts';
import { useRowExpansion } from './DataTableHooks/Expansion';
import { useExport } from './DataTableHooks/Export';
import { useFilter } from './DataTableHooks/Filter';
import { useSelectable } from './DataTableHooks/Selectable';
import { useSorting } from './DataTableHooks/Sorting';
import { PaginationControls } from './DataTablePagination/PaginationControls';
import { Paginator } from './DataTablePagination/PaginationHook';
import { useSimplePagination } from './DataTablePagination/SimplePagination';

export function useDataSource<T>(
  data: T[] | undefined,
  rowID: (row: T) => RowID,
  columns: () => Column<T>[],
  deps: React.DependencyList = []
): DataSource<T> {
  return useMemo(
    () => ({
      data: data ?? [],
      rowID: rowID,
      columns: columns(),
    }),
    [data, columns, rowID, ...deps]
  );
}

export interface DataTableProps<T> {
  /**
   * Datasource represents the table's data and column formatting.
   * The datasource can be created with the "useDataSource()" hook.
   */
  datasource: DataSource<T>;
  /**
   * Dense can be used to enable a dense layout mode that will reduce padding
   * and font-size to facilitate a denser display of information in the table.
   */
  dense?: boolean;
  /**
   * IsLoading is a boolean to indicate the table data is being loaded.
   * The table will display a loading indicator when this prop is "true".
   * Defaults to false.
   */
  isLoading?: boolean;
  /**
   * Expanded may be passed to enable row expansion.
   * The "expanded" prop is a react component that renders
   * a row's expanded contents.
   * Defaults to undefined (i.e. disabled).
   */
  expanded?: React.ComponentType<ExpandedRowRenderProps<T>>;
  /**
   * OnRowSelect may be passed to enable row selection.
   * Defaults to undefined (i.e. disabled).
   * TODO: this feature implementation is not complete and this does not work.
   */
  onRowSelect?: (rows: T[]) => void;
  /**
   * Sortable allows you to globally disable table column sorting.
   * Defaults to false.
   */
  sortable?: boolean;
  /**
   * Filterable allows you to globally disable table filtering.
   * Defaults to false.
   */
  filterable?: boolean;
  /**
   * Exportable allows you to enable the table data export feature.
   * Defaults to false.
   */
  exportable?: boolean;
  /**
   * TODO doc comment
   */
  paginator?: Paginator;
}

function DataTableImpl<T>(props: DataTableProps<T>) {
  const defaultPaginator = useSimplePagination(
    (pageNumber, pageSize) => {
      return Promise.resolve({
        items: drop(props.datasource.data, pageNumber * pageSize).slice(0, pageSize),
      });
    },
    [props.datasource.data]
  );

  // if a paginator is passed in then the parent component is controlling pagination.
  // otherwise we need to use a default pagination implementation.
  const paginator = props.paginator ?? defaultPaginator;

  // if a paginator was passed in then the datasource holds our input data otherwise
  // we need to use the default pagination's paginated dataset (because the props.datasource
  // will be the full dataset).
  // this feels a little messy and i think the fetcher/datasource abstractions need some refactoring.
  const inputRows = props.paginator ? props.datasource.data : defaultPaginator.items;

  const rowID = props.datasource.rowID;

  const expansion = useRowExpansion();

  const selection = useSelectable();

  const dataExport = useExport(props.datasource);

  const filtering = useFilter(inputRows, props.datasource.columns);

  const sorting = useSorting(filtering.rows, props.datasource.columns);

  const outputRows = sorting.rows;

  return (
    <Wrapper>
      <TableParent>
        <Table dense={props.dense}>
          <THead style={{ position: 'sticky', top: 0 }}>
            <TRow>
              <Th colSpan={999} style={{ background: 'white', paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
                <Spacer horizontal gap={0.5} align="center">
                  {props.filterable && (
                    <TextInput
                      name="search"
                      placeholder="Search"
                      value={filtering.filter}
                      onChange={(next) => filtering.setFilter(next)}
                    />
                  )}
                  {props.exportable === true && (
                    <Button secondary onClick={dataExport.export}>
                      Export
                    </Button>
                  )}
                </Spacer>
              </Th>
            </TRow>
            <TRow>
              {/* empty header for row expansion indicator */}
              {props.expanded && <Th />}

              {/* header for global row selection */}
              {props.onRowSelect && (
                <Th>
                  <Checkbox
                    name="select all"
                    value={selection.isAllSelected()}
                    onChange={() => selection.toggleAll()}
                  />
                </Th>
              )}

              {/* headers for the actual data columns provided by the library user */}
              {props.datasource.columns.map((column) => (
                <Th key={columnKey(column)}>
                  <Spacer horizontal gap={0.5} align="center" justify="space-between">
                    {/* column header provided by the library user */}
                    <span>{column.header}</span>

                    {/* column sorting control */}
                    {/* the data table must be sortable */}
                    {/* the column must not have sorting explicitly disabled */}
                    {/* and the column must have a data selector */}
                    {props.sortable && column.sortable !== false && column.selector && (
                      <IconButton
                        icon={
                          sorting.isColumnSorted(column)
                            ? sorting.sorting?.ascending
                              ? ArrowSmallDownIcon
                              : ArrowSmallUpIcon
                            : ArrowsUpDownIcon
                        }
                        onClick={() => sorting.toggle(column)}
                      />
                    )}
                  </Spacer>
                </Th>
              ))}
            </TRow>
          </THead>
          <TBody>
            <MotionConfig transition={layoutTransition}>
              <LayoutGroup>
                {/* row for the loading spinner when isLoading is true */}
                {props.isLoading && (
                  <MotionRow layout>
                    <Td colSpan={999}>
                      <Spacer justify="center">
                        <Spinner size={1} />
                      </Spacer>
                    </Td>
                  </MotionRow>
                )}

                {/* if there are no rows then show a row to indicate that */}
                {!props.isLoading && outputRows.length === 0 && (
                  <MotionRow layout>
                    <Td colSpan={999} style={{ backgroundColor: '#f8f8f8' }}>
                      <Spacer justify="center">No data</Spacer>
                    </Td>
                  </MotionRow>
                )}

                {/* rows for the table data provided by the library user */}
                {outputRows.map((row) => {
                  const id = rowID(row);
                  const expanded = expansion.isExpanded(id);
                  return (
                    <Fragment key={id}>
                      <MotionRow layout>
                        {/* cell for row expansion control */}
                        {props.expanded && (
                          <Td onClick={() => expansion.toggle(id)}>
                            <IconButton icon={expanded ? ChevronDownIcon : ChevronRightIcon} />
                          </Td>
                        )}

                        {/* cell for row selection control */}
                        {props.onRowSelect && (
                          <Td onClick={() => selection.toggle(id)}>
                            <Checkbox name="selected" value={selection.isSelected(id)} />
                          </Td>
                        )}

                        {/* cells for actual data provided by the library user */}
                        {props.datasource.columns.map((column) => (
                          <Td key={columnKey(column)}>
                            {column.render ? (
                              <column.render row={row} value={column.selector?.(row)} />
                            ) : (
                              column.selector?.(row) ?? '-'
                            )}
                          </Td>
                        ))}
                      </MotionRow>

                      {/* if the row is expanded we render an additional row with the expanded row's content */}
                      {expanded && props.expanded && (
                        <MotionRow layout>
                          <Td colSpan={999} style={{ background: '#e6e6e6' }}>
                            <Paper elevation={2}>
                              <props.expanded row={row} />
                            </Paper>
                          </Td>
                        </MotionRow>
                      )}
                    </Fragment>
                  );
                })}
              </LayoutGroup>
            </MotionConfig>
          </TBody>
          {paginator && (
            <TFoot>
              <TRow>
                <Td colSpan={999}>
                  <PaginationControls
                    currentPage={paginator.currentPage}
                    pageSize={paginator.pageSize}
                    onNextPage={paginator.next}
                    onPreviousPage={paginator.previous}
                    onPageSizeChange={paginator.setPageSize}
                  />
                </Td>
              </TRow>
            </TFoot>
          )}
        </Table>
      </TableParent>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-content: stretch;
  overflow: auto;
`;

const TableParent = styled.div`
  display: flex;
  flex: 1;
  ${Table} {
    width: 100%;
  }
`;

const MotionRow = motion(TRow);

const layoutTransition: Transition = {
  type: 'spring',
  duration: 0.4,
};

// we typecast the export because "memo()" breaks generic component
// typing in typescript.
export const DataTable = memo(DataTableImpl) as typeof DataTableImpl;
