import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormItem, FormItemProps } from './FormItem';

export type OnChange<T> = (next: T, event: React.FormEvent | undefined) => void;

export interface FormInputProps<T> {
  /**
   * Name sets the html form label and input name.
   */
  name: string;
  /**
   * Label can be used to add a text label to the form input.
   */
  label?: string;
  /**
   * Tip can be used to add text or a react component to the input's label.
   * The tip will be right-aligned and should be a small textual element.
   */
  tip?: React.ReactNode;
  /**
   * The current value for the form input.
   */
  value?: T;
  /**
   * If the input is a readonly field (i.e. just for display).
   */
  readOnly?: boolean;
  /**
   * If the input is disabled for some reason.
   */
  disabled?: boolean;
  /**
   * Change event handler
   */
  onChange?: OnChange<T>;
  /**
   * Focus blur event handler
   */
  onBlur?: () => void;
  /**
   * TabIndex sets the standard html tabindex property for the input.
   */
  tabIndex?: number;
  /**
   * IsInvalid indicates that the form item is currently failing validation.
   */
  isInvalid?: boolean;
}

export interface FormInputOptions {
  inline?: boolean;
}

export function formInput<T>(component: T, opts?: FormInputOptions): T {
  const ComponentImpl = component as unknown as React.FC<FormInputProps<unknown>>;

  const Wrapper = (props: FormInputProps<unknown> & FormItemProps) => {
    const form = useFormContext();

    const isSubmitting = form?.formState?.isSubmitting;

    if (!form) {
      return (
        <FormItem name={props.name} label={props.label} error={props.error} inline={opts?.inline}>
          <ComponentImpl {...props} />
        </FormItem>
      );
    }

    return (
      <Controller
        control={form.control}
        name={props.name}
        render={({ field, fieldState }) => {
          return (
            <FormItem
              name={props.name}
              label={props.label}
              tip={props.tip}
              error={props.error ?? fieldState.error}
              inline={opts?.inline}
            >
              <ComponentImpl
                {...props}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                disabled={props.disabled || isSubmitting}
                isInvalid={!!(props.error ?? fieldState.error)}
              />
            </FormItem>
          );
        }}
      />
    );
  };

  return Wrapper as unknown as T;
}
