import { AnimatePresence, motion } from 'framer-motion';
import { entries } from 'lodash-es';
import React from 'react';
import { useMemo } from 'react';
import { styled } from 'styled-components';
import { Spacer } from '../Spacer/Spacer';
import { Toast, ToastProps } from './Toast';

interface ToastOverlayProps {
  toasts: Record<string, ToastProps>;
}

export function ToastOverlay(props: ToastOverlayProps) {
  const toasts = useMemo(() => {
    return entries(props.toasts).map(([key, value]) => ({ key, value }));
  }, [props.toasts]);

  return (
    <StyledOverlay>
      <Spacer gap={1}>
        <AnimatePresence>
          {toasts.map((toast) => (
            <motion.div layout key={toast.key} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <Toast {...toast.value} />
            </motion.div>
          ))}
        </AnimatePresence>
      </Spacer>
    </StyledOverlay>
  );
}

const StyledOverlay = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 300px;

  // lots of css in old angularjs code use giga numbers
  // so we must use omega numbers.
  z-index: 999999999999999999;
`;
