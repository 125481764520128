import { Temporal } from '@js-temporal/polyfill';
import React from 'react';
import { useEvent } from '../../Hooks/useEvent';
import { FormInputProps, formInput } from '../FormInput';
import { StyledTextInput } from '../TextInput/TextInput';

export interface TimeInputProps extends FormInputProps<Temporal.PlainTime> {}

export const TimeInput = formInput((props: TimeInputProps) => {
  const value = props.value;

  const onTimeChange = useEvent((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const next = Temporal.PlainTime.from(event.target.value);
      props.onChange(next, event);
    }
  });

  return (
    <StyledTextInput
      type="time"
      onChange={onTimeChange}
      value={value ? value.toString({ smallestUnit: 'minute' }) : ''}
    />
  );
});
