import { motion } from 'framer-motion';
import { isEqual } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { useEvent } from '../../Hooks/useEvent';
import { useMeasure } from '../../Hooks/useMeasure';
import { Popover } from '../../Popover/Popover';
import { FormInputProps, formInput } from '../FormInput';
import { StyledTextInput } from '../TextInput/TextInput';
import { SelectList } from './SelectList';
import { SelectOption } from './SelectOption';

export interface SelectProps<T> extends FormInputProps<T> {
  options: SelectOption<T>[] | T[];
  placeholder?: string;
  width?: number;
  autoWidth?: boolean;
}

export const Select = formInput(<T,>(props: SelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const [autoWidthRef, authWidthBounds] = useMeasure();

  // normalize the props.options value to a SelectOption<T>
  // i.e. if a string[] is passed then convert it.
  // we support this so that it's simple to use the <Select /> element
  // with a hardcoded list of string/number options.
  const options = useMemo(() => {
    return props.options.map((option): SelectOption<T> => {
      if (option && typeof option === 'object' && 'label' in option && 'value' in option) {
        return option;
      }
      return { key: String(option), label: String(option), value: option };
    });
  }, [props.options]);

  // SelectedValue is the currented selected value to display.
  // We find this value by looking up the "value" prop in the given
  // list of "options".
  // We search by reference equality first i.e. the === operator because
  // it's fast and works well for all value types (numbers, strings, object references etc.)
  // If a reference equality match isn't found then we must search by structural equality.
  // The structural equality is slower but is important because the library user may expect
  // objects that look the same to be the same
  // i.e. {"name": "bob"} !== {"name": "bob"} because they are different object references
  // but the user probably expected them to be equal for the purpose of the <Select/> element.
  // So structural equality using lodash's "isEqual" is important.
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const selectedValue = useMemo(() => {
    // search for the selected value using reference equality first
    const found = options.findIndex((option) => option.value === props.value);
    if (found !== -1) {
      return options[found];
    }
    // if the value wasn't found with reference equality then try structural
    return options.find((option) => isEqual(option.value, props.value));
  }, [props.value, options]);

  const inputLabel = selectedValue?.label;

  const inputPlaceholder = props.placeholder ?? 'Select...';

  const onChange = useEvent((next: T) => {
    setOpen(false);
    props.onChange?.(next, undefined);
  });

  // width works in this order:
  // 1. "props.width" is a hardcoded width that takes priority
  // 2. else if "props.autoWidth" is true then use the measured content width
  // 3. else use a default hardcoded width of 240.
  const width = props.width ?? (props.autoWidth ? authWidthBounds.width : undefined) ?? 240;

  const popover = <SelectList options={options} value={props.value} onChange={onChange} width={width} />;

  return (
    <>
      <StyledHiddenSpan ref={autoWidthRef}>{inputLabel ?? inputPlaceholder}</StyledHiddenSpan>
      <Popover
        open={open}
        popover={popover}
        onOpen={(event) => {
          // We must block the event so that the SelectList's
          // search box can auto focus when there are many options.
          // If we don't block the event then the click steals the focus
          // back from the search box.
          event?.preventDefault();
          if (!props.disabled) {
            setOpen(true);
          }
        }}
        onDismiss={() => setOpen(false)}
        placement="bottom-start"
      >
        {width > 0 && (
          <>
            <StyledSelect
              name={props.name}
              value={inputLabel}
              placeholder={inputPlaceholder}
              initial={{
                width: width,
              }}
              animate={{
                width: width,
              }}
              disabled={props.disabled}
              readOnly
            />
            <StyledArrow />
          </>
        )}
      </Popover>
    </>
  );
});

const StyledSelect = motion(styled(StyledTextInput)`
  padding-right: 2rem;
`);

/**
 * This element is used to measure the select element's label size.
 * The idea is to render an offscreen element so that the browser can
 * tell us how big the content is (element.offsetWidth) and then use
 * that to update the actual select box element size.
 * This approach is used because you cannot measure the size the text
 * in an input element directly.
 */
const StyledHiddenSpan = styled.span`
  padding: 0.5rem;
  padding-right: 2rem;
  border: 2px solid red;
  position: absolute;
  top: 0;
  left: -999px;
  opacity: 0;
  z-index: -999;
  pointer-events: none;
`;

const StyledArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.9rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid gray;
`;
