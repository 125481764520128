import { motion } from 'framer-motion';
import { rgba } from 'polished';
import React, { useEffect, useMemo } from 'react';
import { styled } from 'styled-components';
import { useEvent } from '../Hooks/useEvent';
import { Intent, intentColor } from '../Intent/Intent';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface TimeoutBarProps {
  // TODO: refactor rename to "timeout"
  /**
   * Seconds is the number of seconds until the "onTimeout" event will be emitted.
   * Alternatively a Date may be passed to specify a specific time when the timeout
   * should occur i.e. an expiration time.
   *
   * If the value is updated then the timeout bar will reset.
   */
  seconds: number | Date;
  intent?: Intent;
  onTimeout?: () => void;
}

export function TimeoutBar(props: TimeoutBarProps) {
  const durationSeconds = useMemo(() => {
    return typeof props.seconds === 'number' ? props.seconds : (props.seconds.getTime() - Date.now()) / 1000;
  }, [props.seconds]);

  // memoize the callback
  const onTimeout = useEvent(() => props.onTimeout?.());

  useEffect(() => {
    const timeout = setTimeout(() => {
      onTimeout();
    }, durationSeconds * 1000);
    return () => clearTimeout(timeout);
  }, [durationSeconds, onTimeout]);

  return (
    <Bar intent={props.intent}>
      <AnimatedBar
        key={durationSeconds}
        intent={props.intent}
        initial={{ transform: 'translateX(0%)' }}
        animate={{ transform: 'translateX(-100%)' }}
        transition={{ duration: durationSeconds, ease: 'linear' }}
      />
    </Bar>
  );
}

const Bar = styled.div.withConfig({ shouldForwardProp })<{ intent?: Intent }>`
  position: relative;
  height: 6px;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => rgba(intentColor(props.theme, props.intent), 0.3)};
`;

const AnimatedBar = styled(motion.div)<{ intent?: Intent }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${(props) => intentColor(props.theme, props.intent)};
`;
