import React from 'react';
import { styled } from 'styled-components';
import { Flex } from '../Flex/Flex';
import { Icon } from '../Icon/Icon';
import { IconProp } from '../Icon/Icons';
import { Text } from '../Typography/Typography';
import { DrawerItemProps } from './DrawerItem';

export interface DrawerGroupProps {
  label: string;
  icon?: IconProp;
  children?: React.ReactElement<DrawerItemProps> | React.ReactElement<DrawerItemProps>[];
}

export function DrawerGroup(props: DrawerGroupProps) {
  return (
    <Flex align="stretch" style={{ width: '100%' }}>
      <StyledLabel>
        {props.icon && <Icon icon={props.icon} size={1.4} />}

        <Text superBold uppercase nowrap scale={-3}>
          {props.label}
        </Text>
      </StyledLabel>

      <StyledContainer>{props.children}</StyledContainer>
    </Flex>
  );
}

const StyledLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${(props) => props.theme.drawer.paddings.vertical} ${(props) => props.theme.drawer.paddings.horizontal};
  color: ${(props) => props.theme.drawer.group.fg};
  background-color: ${(props) => props.theme.drawer.group.bg};
`;

const StyledContainer = styled.div``;
