import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { css, styled } from 'styled-components';
import * as z from 'zod';
import { Flex } from '../Flex/Flex';
import { capitalize, errorText } from '../Text';
import { typography } from '../Theme/Mixins';
import { Text } from '../Typography/Typography';
import { shouldForwardProp } from '../Utils/shouldForwardProp';
import { useExtraFormContext } from './Form';

export interface FormItemProps {
  label?: string;
  tip?: React.ReactNode;
  error?: any;
  inline?: boolean;
}

export function FormItem(props: FormItemProps & { name: string; children?: React.ReactNode }) {
  const form = useFormContext();
  const context = useExtraFormContext();

  const required = form && context?.schema && isRequired(context.schema, props.name, form.watch());

  if (props.inline) {
    return (
      <Flex horizontal gap={0.5} align="center">
        <Flex horizontal gap={0.2} align="center">
          {props.children}
          {props.label && (
            <FormLabel htmlFor={props.name} style={{ cursor: 'pointer' }} isInvalid={!!props.error}>
              {capitalize(props.label)}
              {required && context.showRequiredStar ? ' *' : ''}
            </FormLabel>
          )}
        </Flex>
        <ErrorMessage>{props.error ? capitalize(errorText(props.error)) : null}</ErrorMessage>
      </Flex>
    );
  }

  return (
    <Flex gap={0.3} align="stretch">
      {props.label && (
        <Flex gap={0.3} justify="space-between" align="flex-start">
          {props.label && (
            <Flex horizontal justify="space-between" align="flex-end" alignSelf="stretch">
              <FormLabel isInvalid={!!props.error}>
                <Text>
                  {capitalize(props.label)}
                  {required && context.showRequiredStar ? ' *' : ''}
                </Text>
              </FormLabel>
              {props.tip}
            </Flex>
          )}
        </Flex>
      )}

      {props.children}

      <ErrorMessage>{props.error ? capitalize(errorText(props.error)) : null}</ErrorMessage>
    </Flex>
  );
}

export const FormLabel = styled.label.withConfig({ shouldForwardProp })<{ isInvalid?: boolean }>`
  ${(props) => typography(props.theme.formItem.label.font)}

  ${(props) =>
    props.isInvalid &&
    css`
      color: ${props.theme.intent.danger.bg};
    `}

  // overriding a legacy global style from ep-web.
  margin: 0;
`;

function ErrorMessage(props: { children: React.ReactNode }) {
  return (
    <AnimatePresence>
      {props.children && (
        <FormErrorMessage
          as={motion.div}
          initial={{ height: 0, opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.2 }}
        >
          {props.children}
        </FormErrorMessage>
      )}
    </AnimatePresence>
  );
}

export const FormErrorMessage = styled.div`
  ${(props) => typography(props.theme.formItem.errorText.font)}
  color: ${({ theme }) => theme.intent.danger.bg};
`;

function isRequired(schema: z.ZodTypeAny, propertyPath: string, _value: any) {
  const nestedSchema = reach(schema, propertyPath);

  if (!nestedSchema) {
    return false;
  }

  return !nestedSchema.isOptional();
}

function reach(schema: z.ZodTypeAny, propertyPath: string): z.ZodTypeAny | undefined {
  // consider property paths may look like these:
  // "example"                      { example: 123 }
  // "example.property"             { example: { property: 123 }}
  // "example.0"                    { example: [123] }
  // "example.property.0.property"  { example: { property: [{ property: 123 }] }}
  return propertyPath.split('.').reduce((currentSchema, segment) => {
    if (!currentSchema) {
      return undefined;
    }

    // if current schema is an object then we return the property associated with the segment
    if (currentSchema instanceof z.ZodObject) {
      return currentSchema.shape[segment];
    }

    // if the segment just has a number and the schema is the expected array schema
    // then we use `.element` to get the array's element schema.
    if (/^[0-9]+＄/.test(segment) && currentSchema instanceof z.ZodArray) {
      return currentSchema.element;
    }

    return undefined;
  }, schema);
}
