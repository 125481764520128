import React from 'react';
import { styled } from 'styled-components';
import { Icon } from '../Icon/Icon';
import { ArrowTopRightOnSquareIcon } from '../Icon/Icons';
import { Anchor, CommonTypographicalProps } from '../Typography/Typography';

export interface ExternalLinkProps extends CommonTypographicalProps {
  href: string;
  children?: React.ReactNode;
  noUnderline?: boolean;
  noIcon?: boolean;
  style?: React.CSSProperties;

  /**
   * CurrentTab can be set to `true` to open the link in the current browser tab.
   * Defaults to `false` so that external links open as new tabs.
   */
  currentTab?: boolean;
}

export function ExternalLink(props: ExternalLinkProps) {
  const { href, currentTab, noUnderline, children, noIcon, ...rest } = props;
  return (
    <StyledAnchor
      rel="noopener noreferrer"
      href={href}
      target={currentTab ? '_self' : '_blank'}
      noUnderline={noUnderline}
      {...rest}
    >
      <span>{children ?? href}</span>

      {!noIcon && <Icon icon={ArrowTopRightOnSquareIcon} />}
    </StyledAnchor>
  );
}

const StyledAnchor = styled(Anchor)<CommonTypographicalProps>`
  display: inline-flex;
  align-items: center;

  // using "em" for the gap so that it scales
  // based on the font-size
  gap: 0.4em;
`;
