import React from 'react';

// heroicons are just SVG react elements
export type SVGIconProps = Omit<React.ComponentProps<'svg'>, 'ref'> & { ref: any };
export type SVGIcon = React.FC<SVGIconProps>;

// IconProp is the type you should use for any component that
// accepts an "icon={}" prop
// i.e. "icon?: IconProp;"
export type IconProp = SVGIcon;

export * from '@heroicons/react/24/outline';
