import { styled } from 'styled-components';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface BoxProps {
  padding?: number;
}

export const Box = styled.div.withConfig({ shouldForwardProp })<BoxProps>`
  padding: ${(props) => (props.padding ?? 0) * 16}px;
`;
