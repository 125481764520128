import { useSyncExternalStore } from 'react';

export function useMediaQuery(query: string): boolean {
  return useSyncExternalStore(
    (subscribe) => {
      const matchMedia = window.matchMedia(query);
      matchMedia.addEventListener('change', subscribe);
      return () => matchMedia.removeEventListener('change', subscribe);
    },
    () => getMatches(query)
  );
}

function getMatches(query: string): boolean {
  if (typeof window !== 'undefined') {
    return window.matchMedia(query).matches;
  }
  return false;
}
