import React from 'react';
import { MontserratFontFace } from './Montserrat';
import { RobotoFontFace } from './Roboto';

/**
 * UIKitFonts loads the fonts used by the default uikit theme.
 */
export function UIKitFonts() {
  return (
    <>
      {/* needed for default theme headings */}
      <MontserratFontFace />

      {/* needed for default theme text */}
      <RobotoFontFace />
    </>
  );
}
