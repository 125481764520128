import { useState } from 'react';
import { useEvent } from './useEvent';

export type UseToggle = [boolean, () => void];

export function useToggle(defaultValue = false): UseToggle {
  const [value, setValue] = useState(defaultValue);

  const toggle = useEvent(() => setValue((current) => !current));

  return [value, toggle];
}
