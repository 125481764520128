import { stringify as csvstringify } from 'csv-stringify/browser/esm/sync';
import { useMemo } from 'react';
import { downloadText } from '../../Download/Download';
import { useEvent } from '../../Hooks/useEvent';
import { CellValue, DataSource } from '../DataTableContracts';

interface ExportHook {
  export: () => Promise<void>;
}

export function useExport<T>(datasource: DataSource<T>): ExportHook {
  const onExport = useEvent(async () => {
    const dataset = datasource.data.map((row) => {
      return datasource.columns.reduce(
        (entry, column) => {
          if (column.selector) {
            entry[column.header] = column.selector(row);
          }
          return entry;
        },
        {} as Record<string, CellValue>
      );
    });

    const csv = csvstringify(dataset, {
      header: true,
    });

    downloadText(csv, 'export.csv');
  });

  return useMemo(
    () => ({
      export: onExport,
    }),
    [onExport]
  );
}
