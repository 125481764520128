import { BrandColors } from '../Theme/Types';

export const brandColors: BrandColors = {
  blue: '#00aeef',
  blueBackground: '#e7f8ff',
  blueLight: '#8ed8f8',
  blueLighter: '#cbecfa',
  blueDeep: '#53688e',
  blueDark: '#1a273f',
};

export const functionalColors = {
  green: '#2ed199',
  greenLight: '#63e7ba',
  greenDark: '#0d8f63',
  greenBackground: '#cffced',
  yellow: '#ffdf38',
  yellowBackground: '#fdf5cb',
  red: '#d40f0f',
  redLight: '#e84343',
  redBackground: '#f7dbdb',
};

export const colors = {
  gray_100: 'hsl(219, 14%, 95%)',
  gray_200: 'hsl(214, 32%, 91%)',
  gray_300: 'hsl(215, 12%, 83%)',
  gray_400: 'hsl(217, 10%, 65%)',
  gray_500: 'hsl(220, 12%, 52%)',
  gray_700: 'hsl(216, 19%, 26%)',
  gray_900: 'hsl(220, 39%, 10%)',

  slate_50: 'hsl(210, 40%, 98%)',
  slate_100: 'hsl(209, 40%, 96%)',
  slate_200: 'hsl(214, 32%, 91%)',
  slate_300: 'hsl(212, 26%, 83%)',
  slate_500: 'hsl(215, 20%, 65%)',
  slate_700: 'hsl(215, 24%, 26%)',
  slate_900: 'hsl(222, 47%, 11%)',

  blue_50: brandColors.blueBackground,
  blue_100: brandColors.blueLighter,
  blue_300: brandColors.blueLight,
  blue_500: brandColors.blue,
  blue_700: brandColors.blueDeep,
  blue_900: brandColors.blueDark,

  indigo_100: 'hsl(228, 96%, 88%)',
  indigo_300: 'hsl(229, 93%, 81%)',
  indigo_500: 'hsl(238, 83%, 66%)',
  indigo_700: 'hsl(244, 57%, 50%)',
  indigo_900: 'hsl(243, 47%, 20%)',

  fuchsia_100: 'hsl(286, 100%, 95%)',
  fuchsia_300: 'hsl(291, 93%, 82%)',
  fuchsia_500: 'hsl(292, 84%, 60%)',
  fuchsia_700: 'hsl(294, 72%, 39%)',
  fuchsia_900: 'hsl(296, 90%, 16%)',

  green_50: 'hsl(112, 76%, 96%)',
  green_100: 'hsl(112, 65%, 86%)',
  green_300: 'hsl(112, 60%, 70%)',
  green_500: 'hsl(112, 80%, 33%)',
  green_700: 'hsl(112, 60%, 33%)',
  green_900: 'hsl(112, 95%, 13%)',

  emerald_50: 'hsl(151., 80%, 95%)',
  emerald_100: 'hsl(160, 88%, 90%)',
  emerald_300: 'hsl(159, 64%, 50%)',
  emerald_500: 'hsl(160, 84%, 39%)',
  emerald_700: 'hsl(162, 93%, 24%)',
  emerald_900: 'hsl(164, 85%, 16%)',

  yellow_50: 'hsl(52, 91%, 95%)',
  yellow_100: 'hsl(52, 90%, 80%)',
  yellow_300: 'hsl(52, 100%, 70%)',
  yellow_500: 'hsl(47, 95%, 53%)',
  yellow_700: 'hsl(40, 96%, 40%)',
  yellow_900: 'hsl(40, 96%, 20%)',

  red_50: 'hsl(0, 85%, 97%)',
  red_100: 'hsl(0, 63%, 91%)',
  red_300: 'hsl(0, 75%, 75%)',
  red_500: 'hsl(0, 86%, 44%)',
  red_700: 'hsl(0, 60%, 33%)',
  red_900: 'hsl(0, 40%, 22%)',
};
