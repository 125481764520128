import { Temporal } from '@js-temporal/polyfill';
import React, { useEffect, useState } from 'react';
import { useEvent } from '../../Hooks/useEvent';
import { Spacer } from '../../Spacer/Spacer';
import { FormInputProps, formInput } from '../FormInput';
import { StyledTextInput } from '../TextInput/TextInput';

export interface DateTimeInputProps extends FormInputProps<Temporal.PlainDateTime> {}

export const DateTimeInput = formInput((props: DateTimeInputProps) => {
  const date = props.value?.toPlainDate();
  const time = props.value?.toPlainTime();

  const [_date, setDate] = useState(date);
  const [_time, setTime] = useState(time);

  useEffect(() => {
    setDate(props.value?.toPlainDate());
    setTime(props.value?.toPlainTime());
  }, [props.value]);

  const onDateChange = useEvent((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const inputValue = event.target.valueAsDate!;

      const date = new Temporal.PlainDate(
        inputValue.getFullYear(),
        // We add 1 to the month because the Date object
        // treats Janurary as month=0 but Temporal uses month=1.
        // You can confirm in a browser console with the following
        // snippet of code: `new Date(Date.parse("2023-01-01T00:00:00.000Z")).getMonth()`
        inputValue.getMonth() + 1,
        // But we don't add anything to the date (day of month) because
        // the Date object indexes days of month from 1.
        // snippet to confirm: new Date(Date.parse("2023-01-01T00:00:00.000Z")).getDate()
        inputValue.getDate()
      );

      setDate(date);

      if (_time) {
        const next = date.toPlainDateTime(_time);
        props.onChange(next, event);
      }
    }
  });

  const onTimeChange = useEvent((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const time = Temporal.PlainTime.from(event.target.value);

      setTime(time);

      if (_date) {
        const next = time.toPlainDateTime(_date);
        props.onChange(next, event);
      }
    }
  });

  return (
    <Spacer horizontal gap={0}>
      <StyledTextInput type="date" onChange={onDateChange} value={_date ? _date.toString() : ''} />
      <StyledTextInput
        type="time"
        onChange={onTimeChange}
        value={_time ? _time.toString({ smallestUnit: 'minute' }) : ''}
      />
    </Spacer>
  );
});
