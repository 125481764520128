import { AnimatePresence } from 'framer-motion';
import { entries, noop } from 'lodash-es';
import React, { RefObject, useContext, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useEvent } from '../Hooks/useEvent';

export type PresentOverlay = React.FC<PresentOverlayProps>;

export interface PresentOverlayProps {
  close: () => void;
}

interface OverlayContextState extends OverlayHook {
  overlays: Record<string, PresentOverlay>;
  portalOutlet: RefObject<HTMLElement>;
}

const OverlayContext = React.createContext<OverlayContextState>({} as any);

export interface OverlayProviderProps {
  children?: React.ReactNode;
}

export function OverlayProvider(props: OverlayProviderProps) {
  const [overlays, setOverlays] = useState<OverlayContextState['overlays']>({});
  const portalOutlet = useRef<HTMLDivElement>(null);

  const close = useEvent((id: string) => {
    setOverlays((overlays) => {
      const next = { ...overlays };
      delete next[id];
      return next;
    });
  });

  const present = useEvent((overlay: PresentOverlay) => {
    const id = nextid();

    setOverlays((overlays) => {
      return {
        ...overlays,
        [id]: overlay,
      };
    });
  });

  const contextValue = useMemo(
    () => ({
      overlays,
      present,
      portalOutlet,
    }),
    [overlays, present]
  );

  return (
    <OverlayContext.Provider value={contextValue}>
      {props.children}

      <div data-testid="overlay-provider-overlay">
        <AnimatePresence>
          {entries(overlays).map(([key, Component]) => (
            <Component key={key} close={() => close(key)} />
          ))}
        </AnimatePresence>
      </div>

      <div data-testid="overlay-provider-overlay-portal" ref={portalOutlet} />
    </OverlayContext.Provider>
  );
}

export interface OverlayHook {
  present: (overlay: PresentOverlay) => void;
}

export function useOverlay(): OverlayHook {
  const context = useContext(OverlayContext);
  return useMemo(() => {
    return {
      present: context?.present ?? noop,
    };
  }, [context]);
}

let id = 1;
function nextid(): string {
  id += 1;
  return String(id);
}

export interface OverlayLayerProps {
  children?: React.ReactNode;
}

export function OverlayLayer(props: OverlayLayerProps) {
  const ctx = useContext(OverlayContext);

  if (!props.children || !ctx.portalOutlet.current) {
    return null;
  }

  return createPortal(props.children, ctx.portalOutlet.current);
}
