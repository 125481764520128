import React, { useEffect, useRef, useState } from 'react';

/**
 * React v18 introduced a double mount/unmount feature enabed with StrictMode.
 * The feature is useful but problematic of async effects.
 *
 * This hook is a utility to work around the issue for async effects.
 *
 * https://blog.ag-grid.com/avoiding-react-18-double-mount/
 */
export function useEffectOnce(effect: () => void | (() => void), deps?: React.DependencyList) {
  const destroyFunc = useRef<void | (() => void)>();
  const effectCalled = useRef(false);
  const renderAfterCalled = useRef(false);
  const [_val, setVal] = useState<number>(0);

  if (effectCalled.current) {
    renderAfterCalled.current = true;

    // resetting the "effect called" flag is a modification from the blog post solution.
    // this allows the effect to run again after a dependency in the deps array changes
    // like the normal useEffect would.
    // our goal is to mimic pre-react 18 useEffect and so it should re-run when the deps
    // have changed.
    effectCalled.current = false;
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: function deps are passed via deps
  useEffect(() => {
    // only execute the effect first time around
    if (!effectCalled.current) {
      destroyFunc.current = effect();
      effectCalled.current = true;
    }

    // this forces one render after the effect is run
    setVal((val) => val + 1);

    return () => {
      // if the comp didn't render since the useEffect was called,
      // we know it's the dummy React cycle
      if (!renderAfterCalled.current) {
        return;
      }
      if (destroyFunc.current) {
        destroyFunc.current();
      }
    };
  }, deps ?? []);
}
