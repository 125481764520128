import { css, styled } from 'styled-components';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

interface Props {
  gap?: number;
  horizontal?: boolean;
  justify?: 'left' | 'right' | 'center' | 'space-between' | 'stretch';
  align?: 'flex-start' | 'center' | 'flex-end';
  padding?: number;
  fullWidth?: boolean;
}

export const Spacer = styled.div.withConfig({ shouldForwardProp })<Props>`
  display: grid;
  grid-gap: ${(props) => props.gap ?? 0}rem;
  grid-template: auto / auto;
  grid-auto-flow: ${(props) => (props.horizontal ? 'column' : 'row')};

  justify-items: ${(props) => props.justify ?? 'start'};
  align-items: ${(props) => props.align ?? 'normal'};
  align-content: flex-start;

  padding: ${(props) => props.padding ?? 0}rem;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  ${(props) => {
    if (props.justify) {
      return css`
        justify-content: ${props.justify};
      `;
    }

    return css`
      justify-content: ${props.horizontal ? 'start' : 'normal'};
    `;
  }};
`;
