import { css, styled } from 'styled-components';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { shouldForwardProp } from '../Utils/shouldForwardProp';

export interface GridProps {
  cols: Breakpoints<number | string>;
  gap?: number;
  rowGap?: number;
  colGap?: number;
  justify?: 'left' | 'right' | 'center' | 'space-between' | 'stretch' | 'normal';
  align?: 'flex-start' | 'center' | 'flex-end';
}

export const Grid = styled.div.withConfig({ shouldForwardProp })<GridProps>`
  display: grid;
  grid-gap: ${(props) => (props.gap ?? 0) * 16}px;
  grid-auto-flow: row;
  grid-template-rows: 1fr;

  justify-items: ${(props) => props.justify ?? 'normal'};
  align-items: ${(props) => props.align ?? 'flex-start'};
  justify-self: ${(props) => props.justify};

  ${(props) => props.rowGap && `grid-row-gap: ${props.rowGap * 16}px;`}
  ${(props) => props.colGap && `grid-column-gap: ${props.colGap * 16}px;`}

  ${(props) =>
    breakpointsMixin(
      props.cols,
      (value) => css`
        grid-template-columns: ${
          typeof value === 'string'
            ? value
            : `repeat(${value}, ${props.justify === 'stretch' ? '1fr' : 'minmax(0, 1fr)'})`
        };
      `
    )}
`;

export interface GridSpanProps {
  span: number | Breakpoints<number> | 'all';
}

export const GridSpan = styled.div<GridSpanProps>`
  ${(props) =>
    breakpointsMixin(
      props.span,
      (value) => css`
        grid-column: ${value === 'all' ? '1/-1' : `span ${value}`};
      `
    )}
`;

export const GridSkip = styled.div``;
