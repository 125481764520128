import { tryGetTraceId } from 'libs/grpckit/src/GrpcWebError';
import React, { useEffect, useMemo, useState } from 'react';
import { Callout } from '../Callout/Callout';
import { intentIcon } from '../Intent/Intent';
import { errorText } from '../Text';

export interface ErrorMessageProps {
  title: string;
  error: unknown;
  intent: 'danger' | 'warning' | 'none';
}

export function ErrorMessage(props: ErrorMessageProps) {
  const [isDismissed, setIsDismissed] = useState(false);

  const traceId = useMemo(() => tryGetTraceId(props.error), [props.error]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: side effect to reset state when inputs change
  useEffect(() => {
    setIsDismissed(false);
  }, [props.error, props.title]);

  return (
    <>
      {!isDismissed && (
        <Callout
          intent={props.intent}
          iconLeft={intentIcon(props.intent)}
          title={props.title}
          subtitle={errorText(props.error) + (traceId ? ` (${traceId})` : '')}
          onDismiss={() => setIsDismissed(true)}
        />
      )}
    </>
  );
}
