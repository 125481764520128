import { useCallback, useLayoutEffect, useRef } from 'react';

// https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation
// biome-ignore lint/complexity/noBannedTypes: <explanation>
export function useEvent<T extends Function>(handler: T): T {
  const handlerRef = useRef<T>(handler);

  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  const callback = useCallback((...args: any[]) => {
    const fn = handlerRef.current!;
    return fn(...args);
  }, []);

  return callback as unknown as T;
}
