import { RefObject, useEffect } from 'react';
import { useEvent } from './useEvent';

type Target = Element | undefined | null | RefObject<Element>;

export function useClickAway(element: Target | Target[], callback: () => void) {
  const elements = Array.isArray(element) ? element : [element];

  const handler = useEvent((event: any) => {
    const isClickOutside = elements.every((element) => {
      if (element instanceof Element) {
        return !element.contains(event.target);
      }

      if (isRef(element) && element.current) {
        return !element.current.contains(event.target);
      }

      return false;
    });

    if (isClickOutside) {
      callback();
    }
  });

  useEffect(() => {
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [handler]);
}

function isRef(element: Target): element is RefObject<Element> {
  if (element && 'current' in element && element.current instanceof Element) {
    return true;
  }
  return false;
}
