import { CSSProperties } from 'react';
import { css, styled } from 'styled-components';
import { colors } from '../Color/Colors';
import { breakpointsMixin } from '../Responsive/Breakpoints';
import { typography } from '../Theme/Mixins';
import { Typography } from '../Theme/Types';
import { shouldForwardProp } from '../Utils/shouldForwardProp';
import { TypeScaleSize } from './TypeScale';

function style(config: Typography) {
  return css`
    ${typography(config)}
    padding: 0;
    margin: 0;
  `;
}

export interface CommonTypographicalProps {
  muted?: boolean;
  bold?: boolean;
  superBold?: boolean;
  currentColor?: boolean;
  uppercase?: boolean;
  nowrap?: boolean;
  underlined?: boolean;
  color?: string;
}

function commonTypographicalStyles(props: CommonTypographicalProps) {
  return css`
    ${
      props.muted &&
      css`
      color: ${colors.gray_500};
    `
    }

    ${
      props.bold &&
      css`
      font-weight: 600;
    `
    }

    ${
      props.superBold &&
      css`
      font-weight: 800;
    `
    }

    ${
      props.currentColor &&
      css`
      color: currentColor;
    `
    }

    ${
      props.uppercase &&
      css`
      text-transform: uppercase;
      letter-spacing: 0.05em; // adding some letter spacing to improve readability
    `
    }

    ${
      props.nowrap &&
      css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `
    }

    ${
      props.underlined &&
      css`
      text-decoration: underline;
    `
    }

    ${
      props.color &&
      css`
      color: ${props.color};
    `
    }
  `;
}

export const H1 = styled.h1.withConfig({ shouldForwardProp })<CommonTypographicalProps>`
  ${({ theme }) => style(theme.typography.h1)}
  ${(props) => commonTypographicalStyles(props)}
`;

export const H2 = styled.h2.withConfig({ shouldForwardProp })<CommonTypographicalProps>`
  ${({ theme }) => style(theme.typography.h2)}
  ${(props) => commonTypographicalStyles(props)}
`;

export const H3 = styled.h3.withConfig({ shouldForwardProp })<CommonTypographicalProps>`
  ${({ theme }) => style(theme.typography.h3)}
  ${(props) => commonTypographicalStyles(props)}
`;

export const H4 = styled.h4.withConfig({ shouldForwardProp })<CommonTypographicalProps>`
  ${({ theme }) => style(theme.typography.h4)}
  ${(props) => commonTypographicalStyles(props)}
`;

export const H5 = styled.h5.withConfig({ shouldForwardProp })<CommonTypographicalProps>`
  ${({ theme }) => style(theme.typography.h5)}
  ${(props) => commonTypographicalStyles(props)}
`;

export const H6 = styled.h6.withConfig({ shouldForwardProp })<CommonTypographicalProps>`
  ${({ theme }) => style(theme.typography.h6)}
  ${(props) => commonTypographicalStyles(props)}
`;

export const Paragraph = styled.p.withConfig({ shouldForwardProp })`
  ${({ theme }) => style(theme.typography.paragraph)}
`;

export const Ul = styled.ul.withConfig({ shouldForwardProp })`
  ${({ theme }) => style(theme.typography.ul)}
  list-style-position: outside;
  padding-left: 1em;
`;

export const Ol = styled.ol.withConfig({ shouldForwardProp })`
  ${({ theme }) => style(theme.typography.ol)}
  list-style-position: outside;
  padding-left: 1em;
`;

export const Li = styled.li.withConfig({ shouldForwardProp })`
  ${({ theme }) => style(theme.typography.li)}

  /* li elements preceded by another li element should have top margin for vertical spacing */
  & ~ & {
    margin-top: 0.25em;
  }

  /* nested ul and ol elements should have indentation */
  & > ${Ul}, & > ${Ol} {
    padding-left: 1.25em;
    margin-top: 0.25em;
  }
`;

export interface TitleProps extends CommonTypographicalProps {
  size: 'h1' | 'h2' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const Title = styled.span.withConfig({ shouldForwardProp })<TitleProps>`
  ${(props) =>
    style({
      fontFamily: props.theme.typography[props.size].fontFamily,
      fontSize: props.theme.typography[props.size].fontSize,
      color: props.theme.typography[props.size].color,
    })}

  ${(props) => commonTypographicalStyles(props)}

  display: block;
`;

export interface TextProps extends CommonTypographicalProps {
  scale?: TypeScaleSize;
  color?: CSSProperties['color'];
}

export const Text = styled.span.withConfig({ shouldForwardProp })<TextProps>`
  ${({ theme }) => style(theme.typography.text)}

  ${(props) => commonTypographicalStyles(props)}

  ${(props) =>
    props.scale &&
    css`
      ${breakpointsMixin(
        props.theme.typography.typeScale(props.scale),
        (value) => css`
          font-size: ${value};
        `
      )}
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;

      ${
        props.muted &&
        css`
        &:hover {
          color: currentColor;
        }
      `
      }
    `}
`;

export interface AnchorProps extends CommonTypographicalProps {
  noUnderline?: boolean;
}

export const Anchor = styled.a.withConfig({ shouldForwardProp })<AnchorProps>`
  ${({ theme }) => style(theme.typography.text)}

  // anchor element should inherit the parent font-size
  // so that it's size matches the content it's used in.
  font-size: inherit;
  text-decoration: ${(props) => (props.noUnderline ? 'none' : 'underline')};

  ${(props) => commonTypographicalStyles(props)}

  &:hover, &:active, &:focus {
    color: ${colors.blue_700};
    text-decoration: ${(props) => (props.noUnderline ? 'none' : 'underline')};
  }
`;
