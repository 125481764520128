import React from 'react';
import { css, styled, useTheme } from 'styled-components';
import { IconButton } from '../Buttons/IconButton';
import { Icon } from '../Icon/Icon';
import { IconProp, XMarkIcon } from '../Icon/Icons';
import { Modal, ModalProps } from '../Modal/Modal';
import { Paper } from '../Paper/Paper';
import { Breakpoints, breakpointsMixin } from '../Responsive/Breakpoints';
import { createSlot, slots } from '../Slots';
import { Spacer } from '../Spacer/Spacer';
import { Title } from '../Typography/Typography';

export interface DialogProps extends ModalProps {
  title?: string;
  iconLeft?: IconProp | React.ReactNode;
  width?: Breakpoints<number | string>;
}

export function Dialog(props: DialogProps) {
  const { title, iconLeft, onDismiss, children, width, ...modalProps } = props;

  const theme = useTheme();

  const slot = slots(children, 'actions');

  return (
    <Modal onDismiss={onDismiss} {...modalProps}>
      <StyledPaper elevation={3} width={width} nopad>
        <Spacer
          gap={0.5 * theme.dialog.gap}
          horizontal
          align="center"
          padding={theme.dialog.gap}
          style={{ position: 'relative' }}
        >
          {iconLeft && <IconOrContent icon={iconLeft} />}
          {title && <Title size="h3">{title}</Title>}
          {onDismiss && (
            <Positioner>
              <IconButton icon={XMarkIcon} onClick={onDismiss} />
            </Positioner>
          )}
        </Spacer>

        <ContentWrapper>{slot.children}</ContentWrapper>

        {slot.actions}
      </StyledPaper>
    </Modal>
  );
}

export interface DialogActionsProps {
  children?: React.ReactNode;
}

export const DialogActions = createSlot('actions', (props: DialogActionsProps) => {
  const theme = useTheme();
  return (
    <StyledDialogActionsBar gap={theme.dialog.gap} horizontal justify="right">
      {props.children}
    </StyledDialogActionsBar>
  );
});

const StyledDialogActionsBar = styled(Spacer)`
  padding: ${({ theme }) => theme.dialog.gap}rem;
  border-top: 1px solid grey;
`;

const StyledPaper = styled(Paper)<{ width?: DialogProps['width'] }>`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  max-height: 85vh;

  ${(props) =>
    breakpointsMixin(
      props.width,
      (value) => css`
        width: ${valueOrPx(value)};
      `
    )}

  ${breakpointsMixin(
    { xs: '90vw', sm: '450px' },
    (value) => css`
      min-width: ${value};
    `
  )}

  ${breakpointsMixin(
    { xs: '90vw', md: '85vw' },
    (value) => css`
      max-width: ${value};
    `
  )}
`;

const Positioner = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.dialog.gap}rem;
`;

function IconOrContent(props: { icon: IconProp | React.ReactNode | undefined }) {
  // case for undefined
  if (!props.icon) {
    return null;
  }

  // case for IconProp which is always a function component
  if (typeof props.icon === 'function') {
    return <Icon icon={props.icon} />;
  }

  // case for React.ReactNode
  return <>{props.icon}</>;
}

const ContentWrapper = styled.div`
  overflow: auto;

  padding-right: ${({ theme }) => theme.dialog.gap}rem;
  padding-left: ${({ theme }) => theme.dialog.gap}rem;
  padding-bottom: ${({ theme }) => theme.dialog.gap}rem;
`;

function valueOrPx(value: string | number): string {
  if (typeof value === 'string') {
    return value;
  }
  return `${value}px`;
}
