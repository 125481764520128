import React from 'react';
import { css, styled } from 'styled-components';
import { checkboxCSSReset } from '../../Buttons/CSSReset';
import { colors } from '../../Color/Colors';
import { useEvent } from '../../Hooks/useEvent';
import { FormInputProps, formInput } from '../FormInput';

export interface CheckboxProps extends FormInputProps<boolean> {
  placeholder?: string;
  autoFocus?: boolean;
  style?: React.CSSProperties;
  type?: 'text' | 'password';
}

export const Checkbox = formInput(
  (props: CheckboxProps) => {
    const onChange = useEvent((event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event.currentTarget.checked, event);
      }
    });

    const onClick = useEvent((event: React.MouseEvent) => {
      if (props.onChange && !props.disabled) {
        props.onChange(!props.value, event);
      }
    });

    return (
      <StyledWrapper onClick={onClick}>
        <StyledCheckbox
          type="checkbox"
          id={props.name}
          name={props.name}
          checked={props.value ?? false}
          onChange={onChange}
          disabled={props.disabled}
          data-testid={props.name}
          tabIndex={props.tabIndex}
        />
      </StyledWrapper>
    );
  },
  {
    inline: true,
  }
);

const StyledWrapper = styled.div`
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
`;

const StyledCheckbox = styled.input`
  ${checkboxCSSReset}

  cursor: pointer;
  width: 18px;
  height: 18px;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${colors.slate_500};
      background-color: ${colors.slate_100};
      border-color: ${colors.slate_300};
    `}

  ${(props) =>
    !(props.disabled || props.readOnly) &&
    css`
      &:focus {
        outline: 3px solid ${props.theme.intent.primary.bg};
        outline-offset: -3px;
      }
    `}
`;
