import { Temporal } from '@js-temporal/polyfill';
import React from 'react';
import { useEvent } from '../../Hooks/useEvent';
import { FormInputProps, formInput } from '../FormInput';
import { StyledTextInput } from '../TextInput/TextInput';

export interface DateInputProps extends FormInputProps<Temporal.PlainDate> {}

export const DateInput = formInput((props: DateInputProps) => {
  const value = props.value;

  const onDateChange = useEvent((event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const inputValue = event.target.valueAsDate!;

      const next = new Temporal.PlainDate(
        inputValue.getFullYear(),
        // We add 1 to the month because the Date object
        // treats Janurary as month=0 but Temporal uses month=1.
        // You can confirm in a browser console with the following
        // snippet of code: `new Date(Date.parse("2023-01-01T00:00:00.000Z")).getMonth()`
        inputValue.getMonth() + 1,
        // But we don't add anything to the date (day of month) because
        // the Date object indexes days of month from 1.
        // snippet to confirm: new Date(Date.parse("2023-01-01T00:00:00.000Z")).getDate()
        inputValue.getDate()
      );

      props.onChange(next, event);
    }
  });

  return <StyledTextInput type="date" onChange={onDateChange} value={value ? value.toString() : ''} />;
});
